.lborder {
  border: 1px solid;
  border-color: #ccc;
  border-radius: 5px;
  line-height: 20px;
  font-size: 10.5px;
  color: #000;
  padding-left: 5px;
  width: 100%;
  /* margin-left: -25px; */
  margin-bottom: 7.5px;
  height: 24.73px;
  padding-top: 1px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}
.checkBoxField {
  margin-right: 10px;
}
.fdiv {
  margin-left: 120px;
  margin-top: -35px;
  padding-right: 5px;
  margin-bottom: 10px;
}
.columnGeneral {
  width: 30%;
}
.columnMapping {
  width: 70%;
}
.maincontainer {
  padding-top: 10px;
  padding-bottom: 30px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.rowIntegration {
  display: flex;
  align-items: flex-start;
}
.primarycard {
  height: 31rem !important;
  line-height: 0.6;
  overflow: auto;
  padding-left: 2px;
}
.primcard {
  padding-top: 14px;
}
.uploadbutton {
  position: inherit;
  float: right;
  margin-top: 12px;
  margin-right: -17px;
  width: 134px;
  height: 25px;
  font-size: 14px;
  text-align: center;
  padding-top: 0px;
  background-color: #6a6aed !important;
}
.ptext {
  top: 30px;
}
.column {
  margin-left: 10px;
}
.seconddiv {
  float: right;
  margin-top: -35px;
}
.scolumn {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  left: -20px;
}
.selectss {
  margin: 0 0 0 0;
  text-align: left;
  font-size: 10px;
  color: black;
  width: 180px;
  margin-bottom: 7.56px !important;
  height: 1px;
}
.f-fdiv {
  width: 90px;
}
.backbutton {
  width: 80px !important;
  height: 25px;
  padding-top: 0px;
  background-color: #089e8f;
  color: white !important;
  text-align: center;
  margin-top: -466px;
}
.lastdiv {
  margin-top: -50px;
}
.span {
  padding-left: 70rem;
  padding-bottom: -10rem;
}
.savebutton {
  width: 70px !important;
  height: 25px;
  background-color: #6a6aed !important;
  padding-top: inherit;
}
.adivv {
  margin-top: -12px;
}
.cancelbutton {
  width: 80px !important;
  padding: 0px 0px 0px 0px;
  height: 25px;
}
.ldiv {
  display: flex;
  float: right;
  margin-left: 700px;
}
.footer-btn {
  display: flex;
}
.intcard {
  padding-top: 0;
  height: 31rem !important;
  overflow-y: auto;
}
.columnss {
  margin-left: 180px;
  margin-top: -2312.2px;
}
.rowss {
  width: 26rem;
  height: 22rem;
  line-height: 0.6;
  margin-top: -12px;
  margin-bottom: 20px;
}
.texb {
  position: absolute;
  left: 50px;
  top: 110px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 0.6;
}
.tex {
  font-weight: 400;
  position: absolute;
  left: 15px;
  line-height: 0.6;
  top: 46px;
  font-style: normal;
  font-size: 12px;
}
.texseller {
  font-weight: 400;
  position: absolute;
  left: 15px;
  line-height: 0.6;
  top: 76px;
  font-style: normal;
  font-size: 12px;
}
.texs {
  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  position: absolute;
  left: 50px;
  line-height: 0.6;
  top: 94px;
}
.texq {
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  left: 50px;
  line-height: 0.6;
  font-style: normal;
  top: 117px;
}
.hr {
  margin: 3%;
  width: 304px;
  margin-left: -9.5%;
}
.texw {
  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  position: absolute;
  left: 50px;
  line-height: 0.9;
}
.text {
  line-height: -0.2;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
}
.stext {
  line-height: -0.2;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.atext {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 10px !important;
}
.otext {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.textp {
  position: absolute;
  left: 15px;
  top: 4px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.texto {
  margin-top: 1px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  left: 1px;
}
.textm {
  margin-left: -36px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}
.colls {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  color: #6e6969;
  left: -39px;
}
.ptextt {
  width: 138px;
  margin-left: -8px;
  position: absolute;
  left: 150px;
  top: 37px;
  font-size: 12px;
}
.ptexttseller {
  width: 138px;
  margin-left: -8px;
  position: absolute;
  left: 150px;
  top: 69px;
  font-size: 12px;
}
.colns {
  margin-left: -60px;
}
.emailinp {
  width: 218px;
  height: 40px !important;
  margin-left: 30px;
  font-size: 10px;
  padding-top: 5px;
  line-height: 12px;
}
.form-selectint {
  height: 24.73px;
  text-align: left !important;
  font-size: 11px !important;
  color: black !important;
  width: 180px !important;
  margin-bottom: 7.5px;
  font-style: normal !important;
  font-weight: 400 !important;
}
.headerrow {
  text-decoration: underline;
  position: relative;
  top: 40px;
}
.errorMessage {
  color: red;
  text-align: left;
  font-size: 12px;
  /* margin-top: -8px; */
}
.errorBackChange {
  background: rgb(233, 24, 59);
}

.headingCsv {
  font-size: 18px;
}

.selectWrapper select {
  margin-bottom: 7px;
  height: 25.23px;
  font-size: 12px;
}

@media all and (max-width: 880px) {
  .rowIntegration {
    flex-direction: column;
  }
  .columnGeneral {
    width: 100%;
  }
  .columnMapping {
    width: 100%;
  }
}
