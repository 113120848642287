@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

/*  styling scrollbars  */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #2c2f3a;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: 'Karla', sans-serif;
  /* font-family: 'Ubuntu', Arail; */
  color: #646770;
  font-size: 0.9em;
}

.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.text-title {
  color: #2e4a66;
}

.text-lightblue {
  color: #469cac;
}

.text-red {
  color: #cc3d38;
}

.text-yellow {
  color: #a98921;
}

.text-green {
  color: #3b9668;
}

.containers {
  display: grid;
  height: 100%;
  grid-template-columns: 0.1fr 1fr 1fr 1fr;
  /* grid-template-rows: 0.2fr 3fr; */
  grid-template-areas:
    'sidebar nav nav nav'
    'sidebar main main main';
}

@media only screen and (max-width: 978px) {
  .containers {
    /* grid-template-columns: 0.1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
      "sidebar nav"
      "sidebar main";*/
    overflow-x: hidden;
  }
}

.navbars {
  background: #28c7b7;
  grid-area: nav;
  height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid lightgray;
  z-index: 9 !important;
}
.jss110 {
  width: 18%;
  position: relative;
  box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 15%);
  margin-right: 125px !important;
  padding-left: 11px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
}

.modal.show .modal-dialog {
  z-index: 2000;
}
