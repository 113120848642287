#sidebar {
  grid-area: sidebar;
  overflow-y: auto;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  width: 70px;
}

div[class*='sidenav---sidenav-subnav'] {
  min-width: 225px !important;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  padding: 30px 20px 20px 20px;
}
.sidebar__img {
  display: flex;
  align-items: center;
}
.sidebar__title > div > img {
  width: 75px;
  object-fit: contain;
}
.sidebar__title > div > h1 {
  font-size: 18px;
  display: inline;
  color: aliceblue;
}
.sidebar__title > i {
  font-size: 15px;
  display: none;
}
.sidebar__menu > h2 {
  color: #fff;
  padding: 0px 30.4px;
  overflow: hidden;
  font-size: 1em;
  word-break: break-all;
  font-weight: bold;
  line-height: 1.7;
  margin-block-end: 0.83em;
  margin-block-start: 0.83em;
}
.jss351 {
  color: #ffffff;
  width: 95%;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.sidebar__link {
  color: #a6b1cc;
  padding: 6px 24px;
  font-size: 1rem;
  border-left: 4px solid transparent;
  margin-bottom: 5px;
  cursor: pointer;
}
.active_menu_link {
  background: #2c2f3a;
  color: #ffffff;
  border-left: 4px solid #0c79f8 !important;
}
.active_menu_link a {
  color: #a6b1cc !important;
}
.sidebar__link:hover {
  background: #2c2f3a;
  color: #ffffff;
  border-left: 4px solid #0c79f8 !important;
}
.sidebar__link > a {
  text-decoration: none;
  color: #a6b1cc;
  font-weight: normal;
  font-size: 0.8em;
  letter-spacing: 0.1rem;
}
.sidebar__link > i {
  min-width: 32px;
}
.sidebar__logout {
  margin-top: 20px;
  padding: 10px;
  color: #e65061;
}
.fa_power_off {
  padding: 4px;
  margin-right: 5px;
}
.sidebar__logout_btn {
  border: 0 none;
  margin: 8px 0px;
  padding: 8px 55px;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #0c79f8;
  border-radius: 5px;
  font-weight: bold;
}
.sidebar__logout > a {
  text-decoration: none;
  color: #e65061;
  font-weight: 700;
  text-transform: uppercase;
}
.sidebar__logout > i {
  margin-right: 10px;
  font-size: 18px;
}
.sidebar_responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
}
.MuiDivider-root-480 {
  height: 2px;
  background-color: #2c2f3a;
  border: 0 none transparent;
}
.footer {
  bottom: 0;
  width: 230px;
  text-align: center;
  z-index: 100;
  margin-top: 30px;
}
@media only screen and (max-width: 978px) {
  #sidebar {
    height: 100%;
  }
  .sidebar__title > i {
    display: inline;
  }
}
.h1span {
  font-size: 25px;
}
.sidenav---sidenav---_2tBP {
  background-image: linear-gradient(to bottom, #0f649a, #3fb1f9) !important;
  z-index: 999 !important;
  position: fixed !important;
  height: 100vh;
}
@media (min-width: 1441px) {
  .sidenav---sidenav---_2tBP {
    height: 100% !important;
  }
}
@media (max-width: 1280px) {
  .sidenav---sidenav---_2tBP {
    height: 100% !important;
  }
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  > *,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f
  > * {
  text-decoration: none;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  .fa {
  vertical-align: top;
  padding: 12px 0 0 0;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T {
  max-height: 40px;
}

.imageIco {
  max-width: 20px;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo {
  opacity: 1 !important;
  height: 40px !important;
}
.logout-btn {
  bottom: 0px;
  text-align: center;
  height: 40px;
  width: 62px;
  font-size: larger;
}
.leftSideBar div[class^='sidenav---navicon'] > a {
  width: 64px !important;
  height: 50px !important;
  line-height: 52px;
  display: block;
}
.disableMenuItem {
  pointer-events: none;
}
.leftSideBar .SubMenu > div {
  display: flex !important;
  /* padding: 0 !important; */
  width: 100%;
  padding: 0 12px !important;
}
.leftSideBar .SubMenu > div i {
  margin-top: 7px;
}
.leftSideBar .SubMenu > div > div[class^='sidenav---navtext'] {
  flex: 1;
}
.leftSideBar .SubMenu {
  padding: 0 12px;
  margin-bottom: 10px;
}
.leftSideBar .SubMenu > div .fa,
.leftSideBar .SubMenu > div svg {
  margin-right: 10px !important;
  color: #1b76af;
}
.leftSideBar .SubMenu > div a {
  text-decoration: none;
  display: block;
  line-height: 2.3;
}
.leftSideBar .sidenav---expanded---1KdUL .SubMenu > div a,
.leftSideBar .sidenav---expanded---1KdUL .SubMenu > div .fa,
.leftSideBar .sidenav---expanded---1KdUL .SubMenu > div svg {
  color: #fff;
  text-decoration: none;
}
.leftSideBar .SubMenu div[class^='sidenav---navicon'] > a {
  width: auto !important;
  height: auto !important;
  line-height: 2.5;
  max-width: 32px;
  min-width: 32px;
}
