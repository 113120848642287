.breadcrumbsRoot{
  display: none;
  padding: 4px 24px;
  position: relative;
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 4%);
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

}
.breadcrumbsRoot_body1{
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
  }
  .breadcrumbsRoot_secondaryColor
  {
    color: #5D616B;
  }
  .breadcrumbsRoot_ol{
    margin: 0;
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
  }
  .MuiBreadcrumbs-separator {
    display: flex;
    margin-left: 8px;
    user-select: none;
    margin-right: 8px;
    color: #A6B1CC;
  }
  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}
.MuiSvgIcon-fontSizeSmall {
    font-size: 1.25rem;
}
.MuiBreadcrumbs-li span{
    color: #2f374b;
}
.jss251 {
    color: #80889b;
    font-size: 0.8em;
    font-weight: 500;
    text-decoration: none;
}
.jss252 {
    width: 20px;
    height: 20px;
    display: flex;
}