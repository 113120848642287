.custom-dim-boxes {
  display: flex;
  flex-direction: row;
}
.custom-dim-row {
  flex-direction: row !important;
  display: flex;
}
.custom-dim-row .col-12 {
  flex: 0 0 50%;
}
.custom-dim-row .form-control {
  width: 55px;
  margin: 5px 13px !important;
}
.custom-box-cm {
  display: flex;
  align-items: center;
  width: 50%;
  flex: 0 0 50%;
  height: 50%;
}
.custom-cm-paddng {
  padding: 0 !important;
  font-weight: 600;
  flex: 0 0 80% !important;
}
.custom-showInflex {
  display: flex;
  align-items: center;
  padding: 0 !important;
}
.custom-showInflex .form-control {
  margin-right: 5px !important;
}
.custom-cm-font-weight {
  font-weight: 600;
}
.servicesWrapper .signtur-mrgn {
  margin-top: 15px;
}
.addplusicon {
  width: 10%;
}
.custom-adiplus {
  display: flex;
}
.returnTo.servicesWrapper .row .col-12 .form-control {
  margin: 12px 0;
  padding: 0 8px !important;
}
.custom-pad {
  padding-right: 5px !important;
  font-weight: 600 !important;
}
.custom-first-input {
  display: flex;
}
.custom-input-padding input,
.custom-input-padding select {
  margin-bottom: 10px;
  width: 100%;
}
.custom-zip {
  margin-bottom: 10px !important;
}
.custom-zip .col-4 .col-12 {
  padding: 0 !important;
}
.custom-cm-box {
  display: flex;
  padding-right: 5px;
  align-items: center;
}
.custom-cm-box .col-6 {
  padding: 0 0 0 3px !important;
  align-items: center;
  display: flex;
  height: 57px;
}
.custom-align-left {
  padding: 0 !important;
}
.custom-row-input {
  display: flex;
  flex-direction: column;
}
.custom-inline-row {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
}
.custom-add-btn .fa {
  margin-right: 10px;
}
.custom-edit-btn {
  color: #1b76af;
}
.custom-btn-bag button {
  border: 0;
  background-color: transparent;
}
.custom-drp-down {
  background: transparent;
  border-radius: 0;
  height: 29px;
  font-weight: 400;
}
.custom-2-btn {
  padding: 0;
  margin: 10px 0;
  border: solid 1px #1b76af;
  width: 50% !important;
}
.custom-add-btn {
  padding-left: 0 !important;
  margin-bottom: 1rem;
}
.custom-add-btn .addplusicon .fa.fa-plus {
  margin: 5px;
}
.custom-minus-btn1 {
  position: absolute;
  top: -69px;
  right: 0px;
}
.custom-margn-lr {
  padding-left: 15px;
  padding-right: 15px;
}
