.table {
  border: solid 1px grey;
  padding: 20px;
  width: 100%;
}
.trow {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  text-align: left;
  margin-top: 50px;
}
.trows {
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  color: #000000;
  line-height: 30px;
  text-align: center;
  margin-top: 50px;
}
.trows-s {
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  color: #000000;
  line-height: 30px;
  text-align: left;
  margin-top: 50px;
}
.hrdiv {
  width: 990%;
  margin-top: -10px;
  margin-left: -19px;
}
.hrdivup {
  width: 104%;
  margin-left: -15px;
  margin-top: -10px;
}
.table .thead {
  text-align: left;
}
.svg {
  margin-left: 50px !important;
}
.button {
  padding: 10px;
  border-radius: 6px;
  border: none;
  color: white;
  display: inline-block;
  cursor: pointer;
}
.success {
  width: 110px !important;
  height: 8px !important;
  text-align: center;
  padding-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 0px;
  line-height: 20px;
}
.td-data-csv {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}
.td-data-csv-csv {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}
.td-data-csvs {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
}
.sds {
  margin-left: 380px;
  margin-top: -40px;
}
.btn-cclose {
  width: 10px;
  height: 20px;
  padding-bottom: 5px !important;
  background-color: aliceblue !important;
  color: rgb(25, 26, 25) !important;
}
.button.btn-back {
  background-color: #089e8f;
  width: 90px;
  text-align: center;
  height: 30px !important;
  padding-top: 5px;
  font-size: 14px;
  margin-top: -3px;
}
.button.btn-csv {
  margin-right: 15px;
  background-color: #6a6aed !important;
  float: right;
  width: 180px;
  height: 30px;
  padding-top: 4px;
  font-size: 14px;
  text-align: center;
}
.button.btnUpload-csv {
  margin-right: 15px;
  background-color: #6a6aed !important;
  float: right;
  width: 110px;
  height: 30px;
  padding-top: 4px;
  font-size: 14px;
  text-align: center;
}
.button.btn-upload {
  margin-right: 20px;
  background-color: #6a6aed !important;
  height: 30px !important;
  padding-top: 4px;
  text-transform: capitalize;
  margin-top: -28.5px;
  float: right;
  width: 110px;
  color: white;
  font-size: 14px;
}
.dialog {
  width: 718px;
  height: 459px;
}
.line {
  width: 718px;
}
.dialog-title {
  width: 149px;
  height: 34px;
  left: 433px;
  top: 180px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  display: flex;
  align-items: center;
  color: #000000;
}
.dialog-title-t {
  position: absolute;
  width: 149px;
  height: 26px;
  left: 433px;
  top: 225px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 34px;
  display: flex;
  align-items: center;
  color: #000000;
}
.button-btn-close {
  position: absolute;
  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;
  border: 1.5px solid #000000;
}
.modal-main {
  height: 459px !important;
  width: 718px !important;
  overflow: hidden !important;
}
.modal-main-div {
  width: 149px !important;
  height: 34px !important;
  top: 180px !important;
  left: 433px !important;
}
.cdn {
  position: fixed;
}
.modal-m-text {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 34px !important;
  color: #000000;
}
.modal-m-h {
  width: 149px;
  height: 26px;
  left: 433px;
  top: 225px;
  margin-bottom: 6px;
}
.modal-m-h-text {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  color: #000000;
}
.m-h-1 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  width: 150px;
  display: flex !important;
  align-items: center !important;
  color: #000000;
  text-align: center;
}
.m-h-2 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #000000;
  width: 300px;
  text-align: center;
}
.m-h-3 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #000000;
  width: 160px;
  width: 350px;
  text-align: center;
}
.m-h-4 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 41px !important;
  color: #000000;
  width: 200px;
  text-align: center;
}
.td-m-1 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}
.td-m-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.greenColor {
  background: #6ab37f;
  padding: 1px;
  padding-left: 6px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center !important;
  color: white;
  display: block;
  width: 72px !important;
  border-radius: 0.25rem;
}
.cus-batch-table .redColor,
.cus-batch-table .greenColor {
  padding-right: 6px;
  word-break: keep-all;
}
.redColor {
  background: #d04545;
  padding: 1px;
  padding-left: 6px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: white;
  display: block;
  width: 72px !important;
  border-radius: 0.25rem;
}
.table .table-sb {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 241px;
}
.table-heading {
  display: flex;
  width: 100%;
}
.wid {
  width: 22% !important;
}
.wid_first {
  width: 20% !important;
  text-align: left !important;
}
.wid_skip {
  width: 20% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.billgenerationship {
  display: flex;
  font-size: small;
  width: 100%;
  line-height: 30px;
}
.billgeneratedetail {
  display: flex;
  width: 80%;
  flex-direction: column;
  padding-left: 16px;
}
.billgeneratecharge {
  display: flex;
  flex-direction: column;
  width: 20%;
}
.tshiphead {
  width: 98%;
  padding-left: 4px;
}

.fa-cloud-download {
  margin-left: 7px;
}

@media all and (max-width: 880px) {
  .table {
    width: max-content;
    min-width: 100%;
  }
}
