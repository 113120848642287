.modal_container_rate_card {
  width: 95%;
  margin-left: 2%;
}
.modal_header {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}
.modal_header_container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  padding: 15px 0 5px 0;
  margin-bottom: 10px;
}
.modal_input {
  margin-bottom: 10px;
}
.btn-chooseFile {
  width: 300px;
  height: 50px;
  margin-bottom: none !important;
  font-style: normal;
  font-weight: 400;
  padding-top: 20px;
  font-size: 14px;
}
.trash-icon {
  margin: '5px 10px 0 10px';
}
.trash-Img {
  width: 20px;
  cursor: pointer;
}
.unit-value {
  padding-left: 6px;
  padding-right: 6px;
}
.unit-margin-level {
  margin-top: 10px;
}
.btn-input-csv {
  border: none;
  border: 1px solid #dadada;
  border-radius: 5px;
}
.drag_drop_file > label {
  min-width: unset !important;
  width: 100% !important;
  height: 150px;
  background: #5eac95;
  color: white;
  border: unset;
}
.drag_drop_file > label > div > span {
  color: white !important;
  font-size: 10px;
}
.delimeter {
  margin-top: 15px;
  margin-left: 95px;
}
.select_react_Csv {
  width: 60%;
}
.header_row_check {
  margin-left: 110px;
  margin-top: 10px;
}
.csv_label {
  margin-top: 10px;
}
.drag_drop_file > label > div > span:nth-child(2) {
  display: none;
}
.status_bar {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}
.status_bar_container {
  margin-right: 0;
  margin-left: 0;
}
.download_template {
  color: rgba(34, 72, 170, 0.98);
  font-weight: 400;
  font-size: 12px;
  margin-top: 20px;
  cursor: pointer;
}
.xlsx_container {
  font-weight: 300;
  font-size: 10px;
}
.cancel_btn_csv {
  background: #23a6f0;
  margin: 0 30px 0 0;
  border-radius: 37px;
}
.cancel_btn_csv:hover {
  background: #1d44cd;
  border-radius: 10px;
}
.modal_btn {
  border-radius: 10px;
  margin-top: 15px;
}
.csv_Buttons {
  text-align: right;
}
.save_btn_csv {
  background: #23a6f0;
  border-radius: 10px;
  margin-right: 20px;
}
.save_btn_csv:hover {
  background: #1d44cd;
  border-radius: 10px;
}
.modaL_footer {
  margin: 20px 0;
}
.file_name_container {
  background: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 6px;
}
.error-dimension {
  margin-left: 300px;
}
.select-error {
  text-align: center;
}
.file-upload {
  text-align: center;
  width: 300px;
}
.error.dialogError {
  margin-bottom: 0;
}

.bulkUpload_input {
  display: flex;
  padding-top: 25px;
  justify-content: center;
}
@media all and (max-width: 680px) {
  .select_react_Csv {
    width: 100%;
  }
}
