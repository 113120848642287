.refreshorderrs {
    border: none;
    background-color: white;
    display: flex;
    margin-left: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content:stretch;
  }
  .rtn-prnt-tm{
    left: -10px;
  }
  .rtn-prnt-bk{
    right: 50px
  }
  .loader-rec{
    text-align: center;
  }