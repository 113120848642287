.navbars {
  grid-area: nav;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  box-sizing: border-box;
  background: #ffffff;
  position: fixed;
  width: calc(100% - 64px);
  height: 70px !important;
  margin-left: 64px;
  border: 0;
  box-shadow: 0px 1px 2px #aabbd3;
}
.nav_icon {
  display: none;
}
.nav_icon > i {
  font-size: 26px;
  color: white;
}
.navbar__left > a {
  margin-right: 30px;
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 700;
}
.navbar__left .active_link {
  color: #265acc;
  border-bottom: 3px solid #265acc;
  padding-bottom: 12px;
}
.navbar__right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar__right > a {
  margin-left: 20px;
  text-decoration: none;
}
.navbar__right > a > i {
  color: #a5aaad;
  font-size: 16px;
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
  padding: 7px;
}
@media only screen and (max-width: 978px) {
  .nav_icon {
    display: inline;
  }
}
@media only screen and (max-width: 480px) {
  .navbar__left {
    display: none;
  }
}
.Dropdown-menu {
  width: 199px !important;
  right: 0px;
  display: flex;
  flex-direction: column;
  border: 1px solid #4d776a !important;
  border-radius: 0 0 5px 5px;
}
.Dropdown-control {
  color: #fff !important;
  width: 200px !important;
  height: 36px !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  padding-left: 12px !important;
  border-radius: 4px !important;
  padding-right: 12px !important;
  background-color: #5eac9575 !important;
  border: 0 none !important;
  padding-top: 8px !important;
  margin-left: -41px;
  font-size: 14px !important;
  font-weight: normal !important;
}
.Dropdown-arrow {
  top: 20px !important;
}
.Dropdown-option {
  font-size: 14px !important;
}
.Dropdown-option:hover {
  background-color: #4d776a !important;
  color: white !important;
}
.jss107 {
  position: relative;
  margin-right: 24px;
}
.jss110 {
  width: 55%;
  height: 35px;
  position: relative;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  padding-left: 16px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
  margin-left: 91px;
}
.jss111 {
  color: inherit;
  width: 100%;
}
.MuiInputBase-root {
  color: #646770;
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1em;
  box-sizing: border-box;
  align-items: center;
  font-weight: 400;
  line-height: 1.1876em;
}
.jss53 svg {
  font-size: 1.5rem;
}
.jss110:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #0c79f8;
}
.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
}
.MuiInputBase-input:focus {
  outline: 0;
}
.jss112 {
  color: #fff;
  width: 80%;
  height: 25px;
  overflow: hidden;
  font-size: 14px;
  margin-left: 8px;
  white-space: nowrap;
  padding: 5px;
  text-overflow: ellipsis;
  letter-spacing: 0;
}
::placeholder {
  color: #e8e8ee;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #e8e8ee;
}
::-ms-input-placeholder {
  color: #e8e8ee;
}
.nav_name {
  color: white;
  font-size: 1em;
}
.navbar_heading {
  color: white;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 500;
  width: 270px;
}
.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij {
  height: calc(100% - 70px);
  overflow-y: auto;
}
.sidenav---sidenav-nav---3tvij {
  display: flex;
  flex-direction: column;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- {
  height: auto;
}
.leftSideBar
  .sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T {
  line-height: 40px;
  font-size: 14px;
  max-height: unset;
  height: 45px;
}
.leftSideBar
  .sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T {
  line-height: 40px;
  font-size: 14px;
}
.cus-loggedin-user-sec {
  display: flex;
  align-items: center;
}
.cus-loggedin-user-sec .prepayAmount {
  padding-right: 10px;
  cursor: pointer;
}
.userDetailSection a {
  color: #343434;
}
.userDetailSection a:hover {
  color: #343434;
  text-decoration: none;
}
.walletBalanceSection {
  cursor: pointer;
  color: #fff;
  margin-right: 16px;
  background: #1b76af;
  padding: 2px 10px 2px 10px;
  border-radius: 4px;
  width: 130px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
.walletBalanceSection.viewOnlyFund:hover {
  cursor: default;
}
.walletBalanceSection.viewOnlyFund {
  cursor: default;
}
.walletBalanceSection span {
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  display: inline-block;
  line-height: 1;
}
.walletBalanceSection:hover {
  cursor: pointer;
  background: #1b76af;
}
.custom-algn-middle {
  align-items: center !important;
}

@media all and (max-width: 680px) {
  .navbars {
    padding-left: 45px;
    padding-right: 5px;
    margin-left: 0;
    min-width: 100%;
  }
  /*
  .leftSideBar .menuWrapper {
    width: 0;
    position: absolute !important;
  } */
}
