.table {
  border: solid 1px grey;
  padding: 20px;
  width: 100%;
}
.table .thead {
  text-align: left;
}
.td-data {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
}
.ccol {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #000000;
  line-height: 40px;
  text-align: center;
}
.button {
  padding: 10px;
  border-radius: 6px;
  border: none;
  display: inline-block;
  cursor: pointer;
}
.head-t {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  /* or 112% */

  display: flex;
  align-items: center;

  color: #000000;
}
.button.btn-back {
  background-color: #089e8f;
  width: 80px;
  text-align: center;
  height: 30px !important;
  padding-top: 5px;
  font-size: 14px;
  color: white !important;
}
.button.btn-csv {
  background-color: #e81070;
}
.button.btn-small {
  text-align: center;
  width: 180px !important;
  background-color: #6a6aed !important;
  height: 30px;
  padding-top: 4px;
  font-size: 14px;
}
