.shipment-container {
  padding: 0;
  overflow: auto;
  /* background-color: white; */
}
@media (min-width: 1441px) {
  .shipment-container {
    height: 100%;
  }
}
.email-labell {
  display: inline-block;
  margin-bottom: 15px;
}
.create-new-button {
  color: #fff;
  background-color: #1b76af;
  padding: 12px 12px;
  text-align: center;
}
.create-new-button > span {
  margin-left: 10px;
}
.notification-email {
  margin-left: 10px;
}
@media (min-width: 1280px) {
  .shipment-container {
    height: 100%;
  }
}

.details {
  border: solid 1px #e4e4e4;
  padding: 5px;
  background-color: #fff;
  font-size: 12px;
}

.pd-lt {
  padding-left: 5px;
}

.pd-rt {
  padding-right: 5px;
}

.pd-rt-0 {
  padding-right: 0px;
}

.pd-lt-0 {
  padding-left: 0px;
}

.mr-tp {
  margin-top: 20px;
}

.mr-bt {
  margin-bottom: 20px;
}

.ship-date {
  padding: 0px 4px;
  color: black;
  margin-left: 57px;
}

.ship-date input {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
}

.sender-header,
.receiver-header {
  padding: 2px 15px 2px 10px;
  width: 70%;
}

.sender-header button,
.receiver-header button {
  font-size: 16px;
}

.sender-header .custom-font-w,
.receiver-header .custom-font-w {
  color: black;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
}

.font-w {
  font-weight: 600 !important;
}

.sender-details {
  border: solid 1px #ccc;
  padding: 10px;
  font-weight: 400;
  size: 10px;
}

.shipment-container select {
  padding: 5px;
  height: auto;
  padding-top: 8px;
  width: 100%;
}

.ship-form {
  font-size: 12px;
}

.shipment-container .form-control {
  padding: 5px;
  height: 30px;
  font-size: 12px;
  margin-top: 5px;
}

.shipment-container .input-group {
  display: block;
  margin-bottom: 10px !important;
}

.shipment-container .units-details .form-control {
  /* height: 20px; */
  padding-right: 22px;
  /* width: 76px; */
}

.shipment-container .parcelRow:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.shipment-container .parcelRow {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 20px;
}
.shipment-container .shipColumn {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 10px;
}

.shipment-container .shipColumn .form-control {
  padding: 0;
  width: 100%;
}
.shipment-container .shipColumn .labelName {
  font-size: 14px;
}

.shipment-container .shipColumn span.txt-label1 {
  padding-left: 4px;
  font-size: 14px;
  font-weight: 400;
}
.shipment-container .shipColumn span.labelName {
  font-weight: 400;
}

.country {
  padding: 0 30px 0 30px;
}

.country .label {
  padding: 5px;
}

.country .select {
  padding-left: 0px;
}

.service-details {
  padding: 5px;
  padding-top: 10px;
}

.service-details.package > div,
.sender-details.address > div {
  margin-bottom: 5px;
}

.service-details.package {
  overflow-y: auto;
}

.fedexLogo {
  height: 40px;
}
.usps-logo {
  /* width: 100%; */
  height: 100%;
  margin-bottom: 10px;
}

.txt-unit {
  font-weight: 400;
}

.txt-detail {
  border: solid 1px grey;
  padding: 1px 7px;
  margin-left: 5px;
}

.units-details {
  font-size: 10px;
  align-items: center;
}
.main__tile_Shipment {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 30px;
}
.txt-heading {
  color: #4b8c79;
  margin-left: 260px;
}

.txt-label {
  position: absolute;
  bottom: 3px;
  right: 18px;
}

.txt-ins-term {
  font-size: 8px;
  color: blue;
}

.sign-select {
  padding: 0;
  height: 20px;
}

.short-txt {
  padding-left: 3px;
}

.btn-save {
  padding: 4px 12px;
  border: solid 1px white;
  background-color: #0c456f;
  color: white;
  text-align: center;
  border-radius: 0;
  font-size: 12px;
  margin-top: 14px;
  /* width: 96px; */
  cursor: pointer;
}
.btn-save:disabled {
  background-color: #ccc;
  color: #333;
}

.btn-load {
  margin-left: 8px;
  width: 96px;
}

.units-details .form-check-input {
  margin-top: 19px;
}

.shipment-container .form-check-input {
  width: auto;
  top: 10px;
}

.form-check-notification {
  width: auto;
}
.btn-container {
  display: flex;
  justify-content: flex-end;
}

.submit-icon,
.edit-icon {
  cursor: pointer;
}

.headers {
  display: inline-flex;
  width: 100%;
}

.arrow-swip {
  margin: 0 5px;
  padding-top: 5px;
  cursor: pointer;
}

.shipment-container .service-name {
  overflow-y: auto;
  margin: 0 0;
}

.shipment-container .package-label {
  padding: 5px;
  color: black !important;
  background-color: white !important;
  font-weight: 600;
}
.shipment-container .selected-txt-name {
  background-color: #e7f4f6;
  border-radius: 10px;
  padding: 3px 5px;
  line-height: 2;
}

.shipment-container .txt-name:hover {
  background-color: #e7f4f6;
}

.shipment-container .txt-name {
  cursor: pointer;
  border-radius: 10px;
  padding: 3px 5px;
  line-height: 2;
}

.btn-ship,
.btn-create {
  padding: 13px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  height: auto;
  border-radius: 5px;
}

.btn-create {
  cursor: pointer;
}

.btn-ship.validated {
  background-color: #089e8f;
  cursor: pointer;
}

.mr-tp-10 {
  margin-top: 10px;
}

.form-select1 {
  background: #f5fbfe;
  color: black !important;
  font-weight: 600;
  border: 1px solid #ced4da;
}

.form-select1.service {
  color: #fff;
}

.form-select {
  background-color: white;
  color: black;
  height: 23px;
}

.package-label {
  background-color: #28c7b7;
  color: white;
}

.validationBox {
  margin-top: 7px;
  border-radius: 3px;

  background-color: rgba(255, 255, 255, 0.5);
}

.validationBox.printLabel {
  text-align: center;
}

.validationBox h2,
.noRate h3 {
  margin: 16px 0 0 0;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: red;
}

.validationBox.noRate {
  margin: 0px 0 0 0;
}
.validationBox h3 {
  margin: 0px 0 0 0;
  word-break: break-all;
}

.validationBox p {
  font-size: 12px;
}

.validationBox ul {
  padding: 10px 0 0 16px;
  margin: 0;
  text-align: left;
}

.validationBox ul li {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666666;

  padding: 5px 0;
}

.notValidated {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.55;
}

.unitSelection {
  display: flex;
}

.td-data-csv2 {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1px;
  text-align: center;
}

.unitSelection .unitDropdown {
  max-width: 93px;
  font-size: 0.75rem;
}

.col-42 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-40 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.addplusicon.shipment {
  width: 15px;
  margin-right: 5px;
}
.addplusicon {
  border: none;
  margin-inline: 10px;
  width: 20px;
  background-color: white;
  margin-top: 12px;
}

.col-41 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-21 {
  padding-left: 15px;
  width: 100px;
  padding-top: 3px;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.de {
  width: 180px;
  margin-left: -14px;
}

/* .col-2b{
  border: none;
} */

.send-notification {
  margin-left: 20px;
}

.em {
  width: 180px;
  margin-top: -17px;
  /* /15 */
}

.m {
  display: inline-block;
  margin-bottom: -7px;
}

.xx {
  width: 350px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: 20px;
}

.on {
  border: none;
}

.col-4q {
  font-size: smaller;
}

.col-4w {
  font-size: smaller;
  width: 150px;
}

.col-4o {
  width: 100%;
  font-size: smaller;
  margin-bottom: 5px;
}

.description {
  width: 211px;
  height: 54px;
  margin-top: 35px;
  margin-bottom: -38px;
  margin-left: -118px;
}

.inputdes {
  width: 100%;
  height: 42px;
  border: 1px solid gray;
}

.ship {
  margin-left: 4px;
  font-size: smaller;
}

.receiver {
  margin-left: 2px;
}

.col-4a {
  width: 140px;
  margin-left: -31px;
}

.col-4b {
  margin-left: 27px;
  padding-left: 31px;
  position: relative;
  z-index: 1;
}

.packageTxt {
  font-size: 12px;
}

.ouncevalue {
  text-align: center;
  width: 100%;
}

.col-9 {
  font-size: 14px;
  font-weight: normal;
}

.qwo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.qwo > div {
  padding-right: 8px;
}

.qwo > div:last-child {
  padding-right: 0;
}

.tqty {
  text-align: center;
  width: 100%;
  overflow-x: scroll;
}

.tweight {
  text-align: center;
  width: 100%;
  overflow-x: scroll;
}

.qq {
  width: 354px;
  height: 25px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.rr1 {
  width: 87px;
  height: 19px;
}

.unitDropdown {
  width: 74px;
  height: 21px;
  /* font-size: 8px; */
}

.form-selectship {
  width: 75px;
  margin-top: 1px;
}

.validationBox > .row > .col-6 {
  font-weight: 500;
}

.validationBox > .row > .col-6.servicetext {
  font-size: small;
  font-weight: 400;
}

.validationBox > .row > .col-12 {
  font-weight: 400;
  font-size: small;
}

.validationBox > .row > .col-12.servicetext {
  font-size: small;
  font-weight: 400;
}

.validationBox > .row > .col-6.servicetext.dollarRight {
  padding-right: 20px;
}

.textAligncenter {
  text-align: center;
}

.flex-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.ordershipment {
  margin-top: 10px;
  padding: 10px 0;
  /* max-height: 574px; */
  background-color: rgba(255, 255, 255, 0.5);
  /* width: 88%; */
  position: relative;
  z-index: 1;
}
.orderListTable tr td .closeIcon {
  display: none;
  position: absolute;
  right: 2px;
  top: 12px;
  color: #d85030;
  font-size: 14px;
  font-weight: 400;
}

.flexitem {
  font-size: small;
  width: 20%;
  display: flex;
  flex-direction: column;
  margin: 10px 0 0;
  justify-content: flex-end;
}
.shipment-icon {
  width: 60px;
  height: 40px;
}

.refreshorder {
  border: none;
  background-color: white;
  display: flex;
  margin-left: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: center;
}

.flexitem2 {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.headingorder {
  width: 100%;
}

.shipordersearchbar {
  display: flex;
  width: 100%;
}

.flexitem2icon {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 13px;
  margin-top: 5px;
}

.inputshiporder::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 0.8; /* Firefox */
}
.inputshiporder {
  display: flex;
  width: 100%;
  height: 40px;

  padding: 0 10px;
  font-size: 12px;
  line-height: 2;
  border: 1px solid #ccc;
}
.orderrefresh {
  font-size: 14px;
  font-weight: 500;
}
.shiporderrecord {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 100%;
  justify-content: flex-start;
  max-height: 750px;
}

.flexcover {
  display: flex;
  flex-direction: row;
  /* width: 50%; */
}

.shipordertimedate {
  display: flex;
  font-size: 12px;
  flex-direction: column;
  /* justify-content: flex-end; */
}

.scrolorder {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 77%;
}

.orderListTable tr:hover {
  cursor: pointer;
  background-color: #f1f1f1;
  border-left: 2px solid #ccc;
  border-left: 0;
}

.orderListTable .selected,
.orderListTable .selected td {
  /* border-left: 2px solid #ccc; */
  background-color: #f1f1f1;
  /* cursor: default; */
}

.orderListTable tr td {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 8px;
}

.number-records {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.orderListTable tr:hover .closeIcon {
  display: block;
}

.orders_list {
  border-bottom: solid 1px #cbced1;
}

.orderListTable {
  word-break: break-all;
}

.filterShippingListModal .row .formLabel {
  text-align: right;
}
.filterShippingListModal .row {
  margin-bottom: 10px;
}

.filterShippingListModal.generateKey .row select {
  width: 100%;
  padding: 0 8px;
  height: auto;
}
.filterShippingListModal .row select {
  width: 95%;
  height: 30px;
  padding: 0px;
  font-size: smaller;
}
.filterShippingListModal .row input {
  width: 95%;
  height: 30px;
}
/*  */
/* .filterShippingListModal .btn-close{} */

.filterShippingListModal .btn-close::before {
  content: 'X';
  visibility: visible;
  color: 'black';
  font-weight: bold;
}

.filterShippingListModal .btn-close {
  /*background-color: red; */
  /* visibility: hidden; */
  position: absolute;
  line-height: 1;
  right: 32px;
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.formHeader {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0 0 16px 0;
}

.formHeader .leftSide {
  width: 50%;
  padding-right: 14px;
}

.referenceField {
  padding: 0;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
}

.referenceField input {
  width: 100%;
}
.referenceField .heading-ref {
  margin-top: 2px;
  font-size: 13px;
}

.formHeader .ship-date {
  width: calc(25% - 8px);
  margin: 0 0 0 8px;
  font-size: 12px;
}

.formHeader .ship-date:first-child {
  margin-right: 10px;
}

.shipmentBooked i,
.validationBox.shipmentBooked h2 {
  color: rgb(110, 169, 103);
  text-align: center;
}

.validationBox.shipmentBooked ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.validationBox.shipmentBooked ul li a {
  color: #212529;
}

.validationBox.shipmentBooked ul li {
  margin-bottom: 5px;
  text-align: center;
  background-color: #f5f5f5;
  border: 1px solid #ececec;
  padding: 6px 0;
  border-radius: 4px;
}
.validationBox.shipmentBooked ul li:hover {
  background-color: #666666;
  color: #fff;
  cursor: pointer;
}
.validationBox.shipmentBooked ul li:hover i {
  color: #fff;
}
.validationBox.shipmentBooked ul li:hover a {
  text-decoration: none;
  color: #fff;
}

.modal-header > .h4 {
  font-size: small;
}

.modalDeleteWrapper .modal-content > .modal-header {
  padding: 20px 20px;
}
.modalDeleteWrapper .modalFooter button {
  background-color: #ccc;
  border: 0;
  padding: 7px 15px;
  margin-left: 10px;
  color: #212529;
}
.modalDeleteWrapper .messageWrapper {
  padding: 25px 0;
  font-size: 13px;
}

.modalDeleteWrapper .modalFooter button.main {
  background-color: #089e8f;
  color: #fff;
}
.modalDeleteWrapper .btn-close::before {
  content: 'X';
  visibility: visible;
  color: 'black';
}
.modalDeleteWrapper .btn-close:hover {
  border: 1px solid #aaa;
}
.modalDeleteWrapper .btn-close {
  /*background-color: red; */
  /* visibility: hidden; */
  position: absolute;
  line-height: 1;
  right: 15px;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 0;
  font-size: 12px;
}

.viewOnly {
  border: 0;
  background: #f5fbfe;
}

.viewOnly:focus,
.viewOnly:focus-visible {
  border: 0;
  outline: 0;
}

.modalDeleteWrapper .saveForm input {
  width: 100%;
  border: 1px solid #c1c1c1;
  line-height: 2;
  padding: 0 4px;
}

.modalDeleteWrapper .modal-main-div {
  width: 100% !important;
}

.modalDeleteWrapper .saveForm input:focus,
.modalDeleteWrapper .saveForm input:focus-visible {
  border: 1px solid #f1f1f1;
  border-color: #99baca;
  outline: 0;
  background: #f5fbfe;
  color: #000;
}

.modalDeleteWrapper .saveForm .formActions {
  margin-top: 10px;
  text-align: right;
}

.orderList .head,
.orderList .body .data-row {
  display: flex;
}

.orderList .body .data-row {
  padding-top: 10px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 10px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}

.orderList .body .data-row:hover {
  background: #f1f1f1;
}
.orderList .delete {
  cursor: pointer;
}

.orderList .head {
  font-weight: 700;
  padding-bottom: 10px;
}

.orderList .body {
  max-height: 200px;
  overflow-y: auto;
}

.orderList .name {
  width: 50%;
}
.orderList .date {
  width: 40%;
}
.orderList .actions {
  width: 10%;
}

.errorField {
  border-color: #dc8d99 !important;
  background: #fff7f8 !important;
  color: #d85030 !important;
}
.margin-tb {
  margin: 10px 0;
}
.ship-button {
  color: #fff !important;
  background-color: #1b76af !important;
  padding: 12px 16px !important;
  text-align: center;
}

/*additional css*/
.Custom-ship-contain {
  overflow: unset;
}
.custom-btn-shipment {
  padding: 9px 14px !important;
  font-size: 12px !important;
}
.Custom-cont-contain {
  padding: 0 !important;
}
.custm-pd-ryt-lft {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.custom-font-w {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.custom-date-td {
  width: 100% !important;
}
.custom-date {
  width: 100px !important;
  padding-right: 20px !important;
}

.custom-scrol {
  overflow-y: auto !important;
}

.save-load {
  margin-top: 10px;
}

.emailBlock {
  margin-bottom: 15px;
  margin-top: 6px;
}

.actionCol {
  text-align: right;
}

.mb-10 {
  margin-bottom: 10px;
}

.row.serviceSelectWrapper {
  margin: 0px;
  margin-top: 20px;
}
.selectHeader .helpingText {
  font-size: 12px;
  margin-top: 5px;
}
.selectHeader {
  text-align: left;
}

.serviceLogo img.premiumLogo {
  max-height: 30px;
}
.serviceLogo img {
  max-height: 25px;
}

.serviceHead {
  font-weight: 700;
  font-size: 14px;
}
.serviceLogo {
  padding-bottom: 36px;
  max-height: 29px;
}

.carrierWrapper {
  border: 3px solid #1b76af;
  padding-bottom: 10px;
}

.commercialInvoice .head input {
  vertical-align: middle;
}

.commercialInvoice .head {
  background-color: #e5e9e9;
  padding: 10px;
  font-size: 12px;
}
.mb-20 {
  margin-bottom: 20px;
}
.commercialInvoice .commercialInvoiceBody {
  padding: 15px;
}
.commercialInvoice {
  margin-top: 15px;
}

.parcelDetails {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.actionInvoice {
  position: absolute;
  bottom: 6px;
}

.alignRight button.saveIconShipment {
  color: #1b76af;
}
.alignRight button i {
  background: transparent;
}
.alignRight button {
  border: 0;
  background: transparent;
}
.alignRight {
  text-align: right;
  padding-right: 10px;
}

#filterInte .spinner-border {
  width: 1rem;
  height: 1rem;
}

.premium span {
  position: absolute;
  left: 35px;
  bottom: -15px;
}
.premium {
  position: relative;
}

.col-shipment-checks {
  display: flex;
}

.senderResidential input {
  margin-right: 10px;
}
.senderResidential {
  display: flex;
  justify-content: flex-end;
}
.ml-8 {
  margin-left: 8px;
}

.validationBox p.shipInfo i {
  color: orange;
  font-size: 16px;
}
.validationBox p.shipInfo {
  padding: 10px;
  background: #e8f3f9 !important;
  color: #333 !important;
}

.fedexForm {
  font-size: 12px;
}

.sendNotfication {
  display: flex;
}

.fedexForm .fedexOption .option input,
.sendNotfication input {
  margin-right: 4px;
}
.fedexForm .fedexOption .option {
  margin: 12px 0;
  display: flex;
  vertical-align: top;
  line-height: 1;
}
.fedexForm .fedexOption {
  padding-left: 20px;
  margin: 10px 0;
}

.flexShipment {
  display: flex;
  justify-content: space-between;
  font-size: small;
  margin: 10px 0 0;
}

@media all and (max-width: 880px) {
  .formHeader {
    flex-direction: column;
    padding: 10px 0;
  }
  .formHeader .ship-date {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
    padding: 0;
  }
  .formHeader .leftSide {
    width: 100%;
  }
  .pd-rt {
    padding-right: 0px;
    padding-left: 0;
  }
  .pd-lt {
    padding-left: 0;
  }
  .shipment-container select {
    width: 100%;
  }
  .senderResidential {
    justify-content: left;
  }
  .save-load {
    margin-bottom: 10px;
    padding: 0 7px;
  }

  .errorShipSection {
    padding: 0;
  }
  .units-details > div {
    margin-bottom: 10px;
  }
  .modal-content {
    width: 100% !important;
  }
  .shipment-cms {
    max-width: 100%;
  }
}
