.history-input-box {
  padding-top: 10px;
  margin-top: 2px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 9px;
  height: 40px;
  width: 110px;
  margin-right: 2000px !important;
  margin-left: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  text-align: center;
}

.description-cell {
  white-space: normal;
  width: 30%;
  word-break: break-all;
}
.date-picker {
  border: 1px solid hsl(0, 0%, 80%);
}
.input-label-fund-history {
  text-align: left;
  margin: auto;
  color: #000000;
  margin-left: 35px;
  font-weight: 700;
}
.amount-align {
  text-align: right;
}

.input-label-balance {
  padding-top: 10px;
  margin-top: 20px;
  height: 30px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
.balance-column {
  margin-left: 460px;
}
.thresh-column {
  margin-left: 100px;
}
@media (max-width: 768px) {
  .balance-column {
    margin-left: 500px;
  }
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  /* margin-left: 100px; */
}
.date-column {
  margin-top: 50px;
}
.input-box-name {
  text-align: center;
  padding-top: 5px;
  justify-content: center;
  margin-top: 25px;
  border-radius: 9px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 40px;
  width: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.input_to {
  margin-left: 10%;
}
@media all and (max-width: 680px) {
  .date-picker {
    width: 100%;
    height: 30px;
  }
  .input-group {
    position: relative;
    display: block;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
}
