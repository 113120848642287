body {
  background: #f3f6f8 !important;
}

main {
  grid-area: main;
  position: relative;
  margin: 70px 0 0 0;
}
.tackingUrl {
  border: 2px solid #e9ecef;
  background-color: #e9ecef;
  border-radius: 4px;
  height: 35px;
}
.tackingUrl a {
  padding: 10px;
}

.tackingUrl.b {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 5px;
}

.validationBox .thresholderrors {
  color: red;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.main__title {
  display: flex;
  align-items: center;
}

.main__tile_heading {
  color: #000;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
}

.main__tile_heading.cus-btn-include-sec h5 {
  font-size: 14px;
}

.main__tile_heading.cus-btn-include-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_content {
  padding: 16px 0px 8px;
}

.main_content_table_history {
  overflow-x: auto;
}
.Edit-Form {
  margin-left: 200px !important;
}
.Edit-Form .col-4 {
  flex: 0 0 33.333333%;
  max-width: 20%;
}
.incomingTbl .table td {
  vertical-align: middle;
  word-break: break-all;
}

.main_content_table_history .table td,
.table th {
  padding: 0.6rem !important;
}

.main_content_table_history .table td.breakWord {
  word-break: break-word;
}

.addressDetailWrapper {
  padding: 20px 30px !important;
}
.border-col::after {
  content: '';
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  background-color: #ccc;
}
.border-col {
  position: relative;
}
.addressForm .col {
  margin-bottom: 15px;
}

.typeAddress label {
  margin-left: 10px;
}
.typeAddress {
  display: flex;
  justify-content: space-between;
}

.main_content_table,
.main_content_table_history,
.incoming_wrapper {
  border: 0;
  padding: 0 0 16px;
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  justify-content: space-between;
  background-color: #fff;
  border-top: solid 1px #ddd;
}

.main_content_table.cus-margin-top {
  margin-top: 48px;
}

.cus-content-only-sec .cus-mar-bottom {
  margin-bottom: 32px;
}

.cus-content-only-sec .status-data.col-4 {
  display: flex;
  align-items: flex-start;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
}

.cus-content-only-sec .input-group {
  flex-direction: column;
}

.main_content_table .table,
.main_content_table_history .table {
  padding: 0;
  border: 0;
}

.main_content_table_history .table td {
  font-size: 13px;
  font-weight: 400;
}

.main_content_table_history .table thead th,
.main_content_table .table thead th {
  border-top: 0;
  border-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  padding: 6px 6px;
}

.main_content_table_history .table thead th {
  background: #fff;
}

.main_content_table .table tbody tr {
  border-top: solid 1px #ddd;
}

.main_content_table .table tbody tr td {
  border-top: 0;
  border-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  padding: 12px 6px;
  word-break: break-all;
}

.no_of_recods {
  color: teal !important;
  font-size: 1em;
}

.main_heading {
  display: flex;
  justify-content: space-between;
}

#portal .containers {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
}

.containers .modals {
  z-index: 1;
  width: 70%;
  min-height: 300px;
  background: white;
  max-height: 500px !important;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  animation: animate 0.3s;
  overflow: scroll;
}

.modals button {
  position: absolute;
  top: 7px;
  right: 7px;
  padding: 8px 11px;
  background: crimson;
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}

@keyframes animate {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}

.error-toast .style_toast-content__kl8dC {
  background-color: #d80c13;
  font-family: inherit;
}

.style_toast-list__378gH.style_right__1o-p7 {
  top: 20px;
}

.float-right {
  float: right;
}

.content-container {
  padding: 20px;
  background-color: white;
}

.error {
  border-color: #dc8d99 !important;
  background: #fff7f8 !important;
  color: #d85030 !important;
}

.error.filterHisory {
  font-size: 12px;
}

.require-field {
  color: #d85030 !important;
}

.requiredthis {
  border-color: #dc8d99 !important;
  background: #fff7f8 !important;
  color: #d85030 !important;
}

.toast-position {
  /* top: 32rem !important; */
  font-size: 12px;
  height: 10px !important;
  bottom: 5rem !important;
  top: auto !important;
}

.alignLeft {
  text-align: left !important;
}

.rightdropdown {
  font-size: 14px;
  font-weight: normal;
}

/** GLobal CSS */
body,
p,
a,
a:hover,
span,
h1,
h2,
h3,
h4,
h5,
h6,
div,
ul,
li {
  font-family: 'Montserrat', sans-serif;
}

button.cus-primary-bg-btn {
  padding: 12px 32px;
  background-color: #1b76af;
  border-radius: 4px;
  border: 0;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: #fff;
}

button.cus-primary-bg-btn:hover {
  border: 0;
  border-radius: 0;
  color: #edebeb;
}

.btn.cus-seconday-bg-btn.round.red {
  background-color: #ff0000;
}

.btn.cus-seconday-bg-btn.round.red:hover {
  background-color: #ff0000;
  border-radius: 50%;
}
.btn.cus-seconday-bg-btn.round {
  border-radius: 50%;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
}
.btn.cus-seconday-bg-btn.round:hover {
  border-radius: 50% !important;
}
.btn.cus-seconday-bg-btn {
  padding: 12px 12px;
  background-color: #1b76af;
  border-radius: 4px;
  border: 0;
  transition: all 0.2s ease-in-out;
  line-height: 1;
  font-weight: 500;
  color: #fff;
  min-width: 60px;
}

.btn.cus-seconday-bg-btn.tabs.active {
  background-color: #1b76af;
  color: #fff;
  height: 58px;
  position: relative;
  bottom: -1px;
  z-index: 1;
  border-bottom: 0;
}
.btn.cus-seconday-bg-btn.tabs.last {
  border-right: 0;
}
.btn.cus-seconday-bg-btn.tabs {
  width: 100%;
  margin-left: 0;
  background-color: #f4f4f4;
  color: #1b76af;
  border-radius: 0;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #ccc;
  border-bottom: 2px solid #fff;
}
.tabWrapper {
  border-bottom: 1px solid #dadada;
}
.btn.cus-seconday-bg-btn.disabled {
  cursor: not-allowed;
}
.btn.cus-seconday-bg-btn:disabled {
  cursor: not-allowed;
}
.btn.cus-seconday-bg-btn:hover:not([disabled]) {
  border: 0;
  border-radius: 0;
  color: #edebeb;
  background-color: #1b76af;
}

.btn.cus-primary-transparent-btn {
  padding: 12px 12px;
  background-color: transparent;
  border-radius: 4px;
  border: 0;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: #1b76af;
}

.btn.cus-primary-transparent-btn:hover {
  border: 0;
  border-radius: 0;
  color: #4597ca;
}

.top_right_button_container .btn.filterIncoming {
  cursor: auto !important;
}
.Back-btn {
  position: absolute;
  top: 20px;
  right: 40px;
}

img {
  max-width: 100%;
}

.alert.alert-danger {
  background-color: transparent;
  color: red;
  font-size: 12px;
  line-height: 14px;
  padding: 4px 2px;
  border: 0;
  margin: 0;
  width: 100%;
  font-weight: 400;
  position: absolute;
  top: calc(100% - 2px);
  left: 0;
}

.form-controlzm::placeholder {
  color: #343434;
  opacity: 0.7;
}

.alert.alert-danger.erroralert {
  margin-top: 0 !important;
  top: 110%;
}

.main__container {
  padding: 20px 32px 24px 25px;
}

.main__container .container-fluid {
  padding: 0;
}

.cus-page-content-sec .content-container .cards.setup-container {
  display: flex;
  flex-wrap: wrap;
}
.cus-page-content-sec
  .content-container
  .cards.setup-container
  .setup-card:nth-child(3n) {
  margin-right: 0;
}

.cus-page-content-sec
  .content-container
  .cards.setup-container
  .setup-card
  .icon {
  width: 35px;
  height: 35px;
  background: #1b76af;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cus-page-content-sec
  .content-container
  .cards.setup-container
  .setup-card
  .icon
  i {
  color: #fff;
}

.cus-page-content-sec
  .content-container
  .cards.setup-container
  .setup-card:hover {
  border: solid 1px #aeaeae;
  background: #fefefe;
}
.cus-page-content-sec .content-container .cards.setup-container .setup-card {
  display: inline-flex;
  align-items: center;
  width: 32%;
  padding: 16px 16px;
  margin-bottom: 20px;
  cursor: pointer;
  background: #f3f6f8;
  border: solid 1px #dcdcdc;
  margin-right: 2%;
  transition: 0.5s ease;
}

.cus-page-content-sec
  .content-container
  .cards.setup-container
  .setup-card
  .txt-name {
  padding-left: 8px;
  font-weight: 500;
  font-size: 14px;
}

.sidenav---sidenav-toggle---1KRjR {
  height: 70px !important;
}

.cus-profile-info-sec {
  padding: 10px 16px;
}

.cus-profile-info-sec .cus-profile-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: solid 1px #ddd;
  margin-bottom: 16px;
}

.cus-profile-info-sec .cus-profile-box .cus-company-sec {
  font-size: 14px;
}

.cus-profile-info-sec .cus-profile-box .cus-logout-sec {
  display: inline-flex;
  align-items: center;
}

.cus-profile-info-sec .cus-profile-box .cus-logout-sec a {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1;
  color: #3fb1f9;
}

.cus-profile-info-sec .cus-profile-box .cus-logout-sec a:hover,
.cus-profile-info-sec .cus-profile-box .cus-logout-sec a:active {
  background-color: transparent;
}

.cus-profile-info-sec .cus-profile-info-name {
  display: flex;
}

.cus-profile-info-sec .cus-profile-info-name .cus-profile-circle-sec {
  padding-right: 16px;
}

.cus-profile-info-sec .cus-profile-info-name .cus-profile-circle-sec span {
  background-color: #1b76af;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
}

.cus-profile-detail-sec .cus-profile-name-sec {
  font-size: 14px;
}

.cus-profile-detail-sec .cus-profile-email-sec {
  font-size: 14px;
  color: #3fb1f9;
  cursor: pointer;
}

/** Login Page Design */

.cus-login-pg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
  background-image: url(/src/assets/images/login-pg-bg-img.jpg);
  background-repeat: no-repeat;
  background-position: left bottom;
}

.cus-login-pg .cus-login-pg-sec {
  display: flex;
  background-color: #fff;
  width: 70vw;
  flex-direction: row;
}

.cus-login-pg-sec .cus-login-pg-left-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-size: cover;
  background-image: url(/src/assets/images/login-pg-sec-bg.png);
  background-repeat: no-repeat;
  background-position: left bottom;
}

.cus-login-pg-left-sec img.cus-main-logo-img {
  max-width: 100%;
}

.cus-login-pg-sec .cus-login-pg-right-sec {
  padding: 40px 32px;
  box-sizing: border-box;
  width: 50%;
}

.cus-login-pg-right-sec .cus-welcome-txt {
  font-size: 32px;
  font-weight: 400;
  color: #616161;
  line-height: 1;
}

.cus-login-pg-right-sec .cus-welcome-txt span {
  font-weight: 800;
  color: #1b76af;
}

.cus-login-pg-right-sec .cus-login-form {
  width: 100%;
}

.cus-login-form .cus-form-sec-heading {
  color: #343434;
  font-size: 30px;
  line-height: 1;
  font-weight: 500;
  margin: 50px 0 30px;
}

.cus-login-pg .cus-action-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
}

.cus-login-pg .cus-action-group .cus-forgot-link-sec {
  color: #3fb1f9;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  transition: all 0.2s ease-in-out;
}

.cus-login-pg .cus-action-group .cus-forgot-link-sec:hover {
  opacity: 0.8;
  cursor: pointer;
}

.cus-login-pg .cus-login-pg-right-sec .cus-link-to-create-account {
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #343434;
  margin: 60px 0 0 0;
}

.cus-login-pg .cus-login-pg-right-sec .cus-link-to-create-account span {
  color: #3fb1f9;
  font-weight: 600;
  margin-left: 6px;
  transition: all 0.2s ease-in-out;
}

.cus-login-pg .cus-login-pg-right-sec .cus-link-to-create-account span:hover {
  cursor: pointer;
  opacity: 0.8;
}

.main_content_table .cus-left-right-col-layout.table .cus-pencil-icon-sec {
  margin-left: 40px;
  cursor: pointer;
}

.react-datepicker__calendar-icon {
  width: 1em !important;
  height: 2em !important;
  margin-left: 5px !important;
}
.uploadNew-modal {
  margin-bottom: 20px !important;
}
.View-Icon {
  cursor: pointer;
}
.action-text {
  margin-right: 25px;
}
.card-Modal .input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
  margin-left: 120px;
}
.card-Modal {
  margin-bottom: 10px;
}
.card-Modal > label {
  margin-left: 5px;
}
.card-Modal-Update > label {
  margin-left: 5px;
}
.card-Modal-Update.input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
  margin-left: 50px;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute !important;
  padding: unset !important;
  left: 3px;
  top: 3px;
}

.shipDateWithCalendar
  .react-datepicker-wrapper
  .react-datepicker__input-container
  .react-datepicker__calendar-icon {
  margin-top: 5px;
}

.cus-login-form-sec .form-group .cus-rel {
  position: relative;
  margin: 20px 0 35px;
  width: 100%;
}

.cus-login-form-sec .form-group.cus-rel {
  position: relative;
}

.cus-login-form-sec .form-group .cus-rel img.cus-sm-icon-cls {
  position: absolute;
  right: 0;
  top: 8px;
  width: 20px;
}

/** Sidenav CSS */
/* #sidebar{
  background-image: linear-gradient(to bottom,#0F649A,#3FB1F9)!important;
} */

.sidenav---sidenav---_2tBP {
  background-image: linear-gradient(to bottom, #0f649a, #3fb1f9) !important;
}

.cus-header-right-sec {
  padding-right: 16px;
}

.userDetailSection a {
  position: relative;
  color: #fff;
}

.userDetailSection a span {
  background: #1b76af;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-weight: 400;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: #fff;
}

.userDetailSection a:after {
  content: '';
  position: absolute;
  right: -1rem;
  top: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border: solid #05619b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.userDetailSection .dropdown-menu {
  min-width: 19em;
}

.dropdown-menu.show {
  right: -16px !important;
}

.dropdown-menu.show a.dropdown-item {
  background-color: none;
}

.dropdown-menu.show a.dropdown-item:after {
  display: none;
}

.batch-select .dropdown-item {
  padding: 0.25rem 1rem;
}

.cus-page-content-sec .cus-tab-heading-sec .tabs .cus-tab-btn:disabled {
  color: #bfbfbf;
}

.cus-page-content-sec .cus-tab-heading-sec .tabs .cus-tab-btn {
  border: 0;
  background-color: transparent;
  color: #212121;
  margin-right: 8px;
  padding: 10px 15px;
  font-size: 13px;
}

.cus-page-content-sec .cus-tab-heading-sec .tabs .cus-tab-btn.active {
  font-weight: 500;
  color: #1b76af;
  border-bottom: solid 2px #1b76af;
  transition: with (border) 0.2s;
  background: #e2e2e2;
}

.cus-content-only-sec .col-4 .input-label-Created-date {
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
}

.table.table-height.cus-table-column-cls thead tr th:nth-child(1),
.table.table-height.cus-table-column-cls tbody tr td:nth-child(1) {
  width: 20%;
}

.table.table-height.cus-table-column-cls thead tr th:nth-child(2),
.table.table-height.cus-table-column-cls tbody tr td:nth-child(2) {
  width: 24%;
}

.table.table-height.cus-table-column-cls thead tr th:nth-child(3),
.table.table-height.cus-table-column-cls tbody tr td:nth-child(3) {
  width: 14%;
}

.table.table-height.cus-table-column-cls thead tr th:nth-child(4),
.table.table-height.cus-table-column-cls tbody tr td:nth-child(4) {
  width: 14%;
}

.table.table-height.cus-table-column-cls thead tr th:nth-child(5),
.table.table-height.cus-table-column-cls tbody tr td:nth-child(5) {
  width: 14%;
}

.table.table-height.cus-table-column-cls thead tr th:nth-child(6),
.table.table-height.cus-table-column-cls tbody tr td:nth-child(6) {
  width: 7%;
  text-align: right;
}

.table.table-height.cus-table-column-cls thead tr th:nth-child(7),
.table.table-height.cus-table-column-cls tbody tr td:nth-child(7) {
  width: 7%;
  text-align: right;
}

.head-company {
  width: 15% !important;
}
.seller-Modal {
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
}
.seller-Modal::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color */
}
.countrytype-error {
  margin-bottom: 0;
}

.delete-company {
  text-align: right;
}
.delete-company span {
  cursor: pointer;
}
.edit-company-address .input-label {
  font-weight: 500;
  line-height: 2.5;
}
.edit-company-address {
  border: 1px solid gray;
  padding: 10px;

  border-radius: 10px;
  margin: 30px 80px 10px;
}
.edit-company-detail {
  padding: 10px;
  border-radius: 10px;
  margin: 30px 150px 10px;
}

.edit-company-detail .input-label,
.Edit-Form-company .formLabel .input-label {
  font-weight: 500;
  font-size: 13px;
  line-height: 2.7;
}

.fnt-wgt {
  font-weight: 500 !important;
}

.fnt-wgt-editmgmt {
  font-weight: 500 !important;
  font-size: 13px;
}

.Edit-Form-company {
  margin-left: 100px !important;
  margin-right: 100px !important;
}
.default-add-label {
  font-weight: 500;
}

.main_content_table .cus-left-right-col-layout.table thead tr th:nth-child(4),
.main_content_table .cus-left-right-col-layout.table tbody tr td:nth-child(4),
.main_content_table .cus-left-right-col-layout.table thead tr th:nth-child(5),
.main_content_table .cus-left-right-col-layout.table tbody tr td:nth-child(5),
.main_content_table .cus-left-right-col-layout.table thead tr th:nth-child(6),
.main_content_table .cus-left-right-col-layout.table tbody tr td:nth-child(6) {
  text-align: right;
}
.company-table .cus-left-right-col-layout.table thead tr th:nth-child(4) {
  text-align: unset;
}
.company-table .cus-left-right-col-layout.table tbody tr td:nth-child(4) {
  text-align: unset;
}

.main_content_table .cus-left-right-col-layout.table thead tr th:nth-child(2),
.main_content_table .cus-left-right-col-layout.table tbody tr td:nth-child(2) {
  width: 27%;
}
.company-table .cus-left-right-col-layout.table tbody tr td:nth-child(5) {
  text-align: unset;
}
.company-table .cus-left-right-col-layout.table thead tr th:nth-child(5) {
  text-align: unset;
}
.company-table .cus-left-right-col-layout.table thead tr th:nth-child(6) {
  text-align: unset;
}
.company-table .cus-left-right-col-layout.table tbody tr td:nth-child(6) {
  text-align: unset;
}
.company-table .cus-left-right-col-layout.table thead tr th:nth-child(2),
.company-table .cus-left-right-col-layout.table tbody tr td:nth-child(2) {
  width: 15%;
}

.main_content_table .cus-left-right-col-layout.table thead tr th:nth-child(3),
.main_content_table .cus-left-right-col-layout.table tbody tr td:nth-child(3) {
  width: 15%;
}

.main_content_table .cus-left-right-col-layout.table thead tr th:nth-child(4),
.main_content_table .cus-left-right-col-layout.table tbody tr td:nth-child(4) {
  width: 15%;
}

.main_content_table .cus-left-right-col-layout.table thead tr th:nth-child(5),
.main_content_table .cus-left-right-col-layout.table tbody tr td:nth-child(5) {
  width: 15%;
}

.main_content_table .cus-left-right-col-layout.table thead tr th:nth-child(6),
.main_content_table .cus-left-right-col-layout.table tbody tr td:nth-child(6) {
  width: 15%;
}
.main_content_table .cus-left-right-col-layout.table thead tr th:nth-child(7),
.main_content_table .cus-left-right-col-layout.table tbody tr td:nth-child(7) {
  width: 15%;
}

.rightWidgetArea.rightSideBar .editHead {
  display: inline-flex;
  margin-bottom: 16px;
}

.plusIcon {
  margin-left: 15px;
}
.addressFields {
  border: 1px solid gray;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.rightWidgetArea.rightSideBar .formDetail {
  margin-bottom: 16px;
  padding-left: 20px;
  border-bottom: solid 1px #ddd;
  padding-bottom: 16px;
}

.rightWidgetArea.rightSideBar {
  background: #fff;
  padding: 24px 16px;
}

.rightWidgetArea.rightSideBar .widgetBox.batchActons h2,
.rightWidgetArea.rightSideBar .noOrderSelected h2 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  border-bottom: solid 1px #6b6969;
  padding-bottom: 8px;
  margin-bottom: 20px;
}

.rightWidgetArea.rightSideBar .noOrderSelected .cus-no-record {
  font-size: 16px;
}

.rightWidgetArea.rightSideBar .editHead label {
  margin: 0 0 0 6px;
  font-size: 14px;
  font-weight: 500;
}

.col-12.actions {
  display: flex;
  justify-content: space-between;
}

.table-height.cus-batch-table {
  border: 0;
}

.table-height.cus-batch-table thead tr th {
  border-top: 0;
  border-bottom: 0;
  font-size: 13px;
  font-weight: 600;
  padding: 6px 6px;
  line-height: 16px;
}

.table-height.cus-batch-table tbody tr {
  border-top: solid 1px #ddd;
}

.table-height.cus-batch-table tbody tr td {
  border-top: 0;
  border-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  padding: 12px 6px;
  text-align: left;
  word-break: break-all;
}

.table-height.cus-batch-table tbody tr td p,
.table-height.cus-batch-table tbody tr td p label {
  margin: 0;
}

.bookingSection.rightWidgetArea .create-batch.batchBooking {
  display: flex;
  justify-content: space-between;
}

.bookingSection.rightWidgetArea .batchBooking {
  text-align: right;
  margin-top: 10px;
}

.row.bacthWrapper .topSectionSticky {
  background: #fff;
  padding: 16px;
  margin-bottom: 16px;
}

.bookingSection.rightWidgetArea .create-batch.batchBooking .batch-select {
  display: inline-flex;
}

.batch-select .headingBatch {
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  align-items: center;
  display: inline-flex;
}

.batch-select .rbt {
  position: relative;
}

.batch-select .rbt .rbt-aux {
  position: absolute;
  right: 25px;
  top: 5px;
}

.batch-select .rbt:after {
  content: '';
  position: absolute;
  right: 12px;
  top: 11px;
  border: solid #a29c9c;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.custom-algn-middle .dropdown a {
  text-decoration: none;
}

.cus-details {
  padding: 10px 0 0;
  justify-content: space-between;
  font-size: 13px;
}
.recent-bth-print {
  margin-left: 5px;
  padding-top: 6px;
}

.historyPopper .dropdown-menu {
  min-width: 13em;
  font-size: 13px;
}
.historyPopper button {
  background: none;
  border: 0;
  color: #212121;
  padding: 0 10px;
}

.historyPopper button:disabled i {
  color: #212121;
}
.historyPopper button:disabled {
  background-color: transparent !important;
}
.historyPopper button.dropdown-toggle:hover,
.historyPopper button.dropdown-toggle:focus,
.historyPopper button.dropdown-toggle:active,
.historyPopper.show > .btn-primary.dropdown-toggle:focus,
.historyPopper.show > .btn-primary.dropdown-toggle,
.historyPopper.show > .btn-primary.dropdown-toggle:active {
  background: none !important;
  padding: 0 10px;
  background-color: #fff !important;
  color: #212121 !important;
  outline: none;
  border: 0;
}

.historyPopper button:after {
  content: '';
  border: none;
}

.loader-wrapper {
  text-align: center;
}

.form-grp-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control.emailBodyField {
  height: 150px !important;
  white-space: pre-wrap;
}

.emailForm .row label {
  font-weight: 600;
  font-size: 15px;
}

.emailForm .row {
  margin-bottom: 12px;
}

.formActionsEmailForm {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: end;
}

.formActionsEmailForm button {
  margin-left: 10px;
}

.batchBookingBtn {
  margin-right: 10px;
}

.activeStatus.activeStatusInprogress {
  background: #f8766d;
}
.activeStatus {
  border-radius: 4px;
  text-align: center;
  padding: 4px 20px;
  color: white;
  background: #54a547;
  min-width: 115px;
  line-height: 1.5;
  display: inline-block;
}

.errorStatus {
  border-radius: 4px;
  text-align: center;
  padding: 4px 20px;
  min-width: 115px;
  line-height: 1.5;
  color: white;
  background: #cc3030;
  display: inline-block;
}

.selectSvcModal .delimeter {
  margin-left: 0;
}

.selectSvcModal .form-check-input {
  position: static;
  margin: 0;
}

.containers .btn-primary.disabled,
.containers .btn-primary:disabled {
  background-color: #66a3c8;
  color: #fff;
}

.marginLeft5 {
  margin-left: 5px;
}

.selectSvcModal .checkBoxWrapper {
  margin-top: 12px;
  align-items: center;
  display: inline-flex;
}

.modaL_footer .noPadding {
  padding: 0;
}

.productInfo {
  max-width: 400px;
  z-index: 2;
  line-height: 1.4 !important;
  font-size: 13px !important;
}
.palletInfo {
  max-width: 200px;
  z-index: 2;
}

.tooltipWidth ul {
  margin: 0;
  padding: 0 0 0 10px;
}

.react-switch-bg {
  background-color: #1b76af !important;
}

.filterBatch .row {
  margin-bottom: 10px;
}

.shipDateWithCalendar .calendarIcon {
  position: absolute;
  right: 8px;
  top: 15px;
  color: #1b76af;
  pointer-events: none;
  cursor: pointer;
}

.shipDateWithCalendar {
  position: relative;
}

.penconEditIcon {
  margin-left: 10px;
  cursor: pointer !important;
}

.editIcon {
  max-width: 22px;
}

.btn.cus-seconday-bg-btn,
.btn.cus-primary-transparent-btn {
  font-size: 0.75rem;
}

/*Sticky table*/

.tableFixHead {
  overflow: auto;
  height: 80vh;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 2;
}

.filterContainer .searchInputBox input {
  min-height: 43px;
}

.filterContainer .searchInputBox {
  flex: 1;
}

.filterContainer.historyFilter .date-picker {
  padding-left: 30px;
}
.filterContainer.historyFilter {
  display: block;
}

.filterContainer label,
.filterContainer .fieldAndLabel,
.createBatchForm {
  font-size: 13px;
}
.filterContainer {
  transition: all 0.5s ease-in-out;
  max-height: 0;
  overflow: hidden;
  display: flex;
}

.show.filterContainer.historyFilter {
  transition: none;
}
.show.filterContainer.historyFilter.barcode {
  transition: none;
  background: white;
}
.show.sellerCarrier.filterContainer {
  background: transparent;
}

.show.filterContainerZIndex {
  z-index: 4;
}
.show.filterContainer {
  transition: all 0.5s ease-in-out;
  max-height: none;
  overflow: visible;
  z-index: 3;
  position: relative;
  background: #f3f6f8;
}

.productList .show.filterContainer {
  background: #fff;
}

.filterContainer .date-picker {
  width: 100%;
}

.inputHistory {
  margin-left: 10px;
  display: block;
}

.fieldAndLabel {
  display: flex;
  align-items: baseline;
}

.fieldAndLabel.csvInte {
  line-height: normal;
}

.labelHead {
  font-weight: 500;
}

.mb10 {
  margin-bottom: 25px;
}

.servicesDropDown {
  width: 100%;
}

.servicesDropDown .optionService {
  font-size: 13px;
  padding: 6px;
}

.wordWrap {
  word-wrap: break-word;
  word-break: break-all;
}

.sortColumn.selected i {
  opacity: 0;
}

.sortColumn:hover i {
  opacity: 0;
}

.sortColumn i {
  opacity: 0;
}

/* Incoming */
.incomingAccordion .accordion-header {
  width: 100%;
}

.incomingAccordion .accordion-item {
  margin-bottom: 15px;
}

.incomingAccordion .accordion-header button:hover i {
  color: #1b76af;
}

.incomingAccordion .accordion-header button:hover {
  background-color: #b8dff8;
}

.incomingAccordion {
  width: 100%;
  transition: 1s ease;
}

.incomingAccordion .accordion-header button.collapsed i.fa-chevron-down {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.incomingAccordion .accordion-header button.addConsole {
  padding: 0;
  border: 0;
}
.incomingAccordion .accordion-header button {
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 600;
  padding: 20px;
  background-color: #b8dff8;
  border: 1px solid #ccc;
}

.incomingAccordion .accordion-header button.collapsed {
  background-color: #f1f1f1;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding: 20px;
}

.incomingAccordion .accordion-header button i {
  color: #1b76af;
}

.packageCard {
  border-radius: 4px;
  max-width: 292px;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0px 1px 2px #1b76af;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: all ease 0.2s;
}

.packageCard:hover {
  background: #fafafa;
  box-shadow: 0px 1px 3px #1b76af;
}

.packageCard:active {
  box-shadow: 0px 0px 0px #1b76af;
}

.packageCard:nth-child(4n) {
  margin-right: 0;
}

.packageCard .dateBox {
  border-right: 1px solid #ccc;
}

.text-height {
  min-height: 80px;
  font-size: 13px !important;
}

.font-weight-boldss {
  font-weight: 440 !important;
}

.packageCard .dateBox,
.packageCard .packageBox {
  width: 50%;
  padding: 10px 15px;
  text-align: center;
  font-size: 13px;
}

.accordion-body {
  padding-top: 10px;
}

.packages {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.dateBox .date {
  font-weight: 600;
}

.packageBox .totalPackages {
  font-weight: 700;
  color: #0056b3;
}

.packageCard .packageBox {
  padding-left: 30px;
}

.main_content_table_incoming {
  border: 0;
  padding: 16px 8px 16px;
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  justify-content: space-between;
  background-color: #fff;
  border-top: solid 1px #ddd;
}

.actionItem {
  border: 0 none;
  background: transparent;
  padding: 5px;
}

.actionItem i {
  color: #0056b3;
}

.packageDate {
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}

.searchBox {
  position: relative;
}

/* Filter menu */

.menuWrapper {
  position: relative;
}

.menuWrapper .filterWrapper {
  position: absolute;
  right: 0;
  z-index: 99;
  box-shadow: 0px 2px 3px #ccc;
}

.filterWrapper .filters .filterMenu .searchMenu {
  margin-bottom: 10px;
}

.filterWrapper .filters .filterMenu .menu {
  min-height: 150px;
  max-height: 150px;
  overflow-y: auto;
  text-align: left;
}

.filterWrapper .filters .filterMenu .menu input {
  margin-right: 10px;
}

.filterWrapper .filters .filterMenu {
  padding-left: 15px;
  width: 70%;
}

.filterWrapper .filters .filterOptions .item:hover {
  background: #dee2e6;
}

.filterWrapper .filters .filterOptions .item.active {
  background: #0056b3;
  color: #fff;
}

.filterWrapper .filters .filterOptions .item {
  padding: 10px 10px;
  cursor: pointer;
}

.filterWrapper .filters .filterOptions {
  border-right: 1px solid #dee2e6;
  padding-right: 15px;
  text-align: left;
  width: 200px;
}

.filterWrapper .filters {
  display: flex;
  padding: 15px;
  color: #212121;
}

.filterWrapper .filterheader .closeBtn {
  background-color: transparent;
  border: 0;
}

.filterWrapper .filterheader h2 {
  font-size: 18px;
  font-weight: 700;
  color: #212121;
  line-height: 2;
  margin-bottom: 0;
}

.filterWrapper .filterheader {
  display: flex;
  justify-content: space-between;
  padding: 8px 15px;
  border-bottom: 1px solid #dee2e6;
}

.filterWrapper {
  width: 550px;
  background: #fff;
}

.filterAction {
  padding: 10px;
  text-align: right;
}

.filterBtn {
  background: #fff;
  border: none;
  padding: 12px;
  line-height: 1;
}

.unit-margin-level label {
  margin-bottom: 0;
  margin-right: 20px;
}

.unit-margin-level {
  display: flex;
}

.incomingTbl div.sortColumn i,
.incomingTbl div.active i {
  margin-left: 5px;
}

.incomingTbl div.sortColumn:hover i {
  opacity: 1;
}

.incomingTbl div.sortColumn,
.incomingTbl div.active {
  cursor: pointer;
}

.incomingTbl div.active i {
  color: #0056b3;
}

.incomingTextarea {
  min-height: 75px;
}

.cus-content-only-sec .cus-mar-bottom20 {
  margin-bottom: 20px;
}

.fileUpload .hiddenFileField {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.fileUpload.inventory {
  display: block;
}
.fileUpload {
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.selectedFile {
  box-shadow: 1px 1px 2px #ccc;
  background: #fff;
  padding: 4px 7px 4px 10px;
  margin-top: 5px;
  display: inline-block;
}

.selectedFile .fileName {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  display: inline-block;
  white-space: pre;
}

.selectedFile button {
  margin-left: 10px;
  border: 0 none;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  line-height: 17px;
  background: #0056b3;
  color: #fff;
}

.col-12 select.noMarginBtm {
  margin-bottom: 0 !important;
}

.form-control.form-select-sm {
  height: 36px !important;
  width: 100%;
  font-size: 0.75rem;
}

.btn.selectYear {
  padding: 0;
}
.selectYear {
  display: inline-block;
  vertical-align: top;
}

.innerImgWrapper button.primary.delete {
  /* position: absolute;
  bottom: 10px;
  right: 45px; */
  width: 25px;
  height: 25px;
  margin-left: 10px;
}
.innerImgWrapper button.primary {
  /* position: absolute;
  bottom: 10px;
  right: 10px; */
  width: 25px;
  height: 25px;
  border: 1px solid #ccc;
}

.innerImgWrapper {
  background: #f1f1f1;
  text-align: center;
}

.recentBatchList {
  height: 350px;
  overflow: auto;
}

.incomingTbl .thumbImg {
  max-width: 50px;
  cursor: pointer;
}
.filters-country {
  padding: 0 15px;

  width: 100%;
  justify-content: right;
  margin-top: 20px;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  min-width: 295px !important;
}
.viewImageWrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
}
.history-margin {
  margin-bottom: 50px;
}
.slick-slide img {
  margin: 0 auto;
}

.slick-slide {
  position: relative;
}

.imageOption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
}

.filterShippingListModal.generateKey .row input {
  width: 100%;
}
.filterShippingListModal.generateKey .modaL_footer {
  padding: 0 15px;
  display: flex;
  width: 100%;
  justify-content: right;
  margin-bottom: 0;
}
.btn.generateBar {
  font-size: 12px !important;
}
.filterShippingListModal.generateKey .row .formLabel {
  line-height: 35px;
}
.keyFieldWrapper {
  display: flex;
}

.keyFieldWrapper input {
  max-width: 60%;
}
.keyFieldWrapper button {
  opacity: 0;
  padding: 0;
  margin-left: 10px;
}
.keyFieldWrapper button:hover {
  color: #0056b3;
}
.keyFieldWrapper:hover button {
  opacity: 1;
}

.activeStatusBtn {
  border-radius: 4px;
  text-align: center;
  padding: 4px 20px;
  min-width: 50px;
  color: white;
  background: #54a547;
  margin-top: -2px;
  width: 105px;
  display: inline-block;
}
.activeStatusBtn.inactiveBtn {
  background: #cc3030;
}

.showAddress .row {
  margin-bottom: 10px;
}

.modal-content .form-control {
  width: 100%;
  font-size: 13px;
}

.carrierConfig .section h2 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}
.carrierConfig .section label {
  width: 100%;
}
.carrierConfig .section {
  padding: 15px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
}

.chipContainer {
  word-break: normal;
}
.sellerChip {
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 0px 8px;
  display: inline-block;
  font-size: 12px;
}

.showAddition:hover {
  background-color: #ccc;
  cursor: pointer;
}
.list__control {
  max-height: 100px;
  overflow-y: auto;
}

.listActionIcon {
  margin-right: 10px;
  cursor: pointer;
}

@media all and (max-width: 1000px) {
  .incoming_wrapper {
    overflow-x: auto;
  }
  .cus-page-content-sec .content-container .cards.setup-container {
    flex-wrap: wrap;
  }
  .cus-page-content-sec .content-container .cards.setup-container .setup-card {
    width: 47%;
  }
  .cus-page-content-sec
    .content-container
    .cards.setup-container
    .setup-card:nth-child(3n) {
    margin-right: 2%;
  }
  .cus-page-content-sec
    .content-container
    .cards.setup-container
    .setup-card:nth-child(2n) {
    margin-right: 0;
  }
}

@media all and (max-width: 768px) {
  .cus-page-content-sec .content-container .cards.setup-container .setup-card {
    width: 100%;
    margin-right: 0%;
  }
  .cus-page-content-sec
    .content-container
    .cards.setup-container
    .setup-card:nth-child(2n),
  .cus-page-content-sec
    .content-container
    .cards.setup-container
    .setup-card:nth-child(3n) {
    margin-right: 0;
  }
}

select option:disabled {
  color: #ccc;
}
.success-icon {
  color: rgb(84, 165, 71);
}
.error-icon {
  color: #ff0000;
}

.align-right {
  text-align: right;
}

.statusRedirect .icon i {
  margin: 0 auto;
}
.statusRedirect .icon.error i {
  color: #ff0000;
}
.statusRedirect .icon.error {
  color: #ff0000;
  border: 4px solid #ff0000;
}
.statusRedirect .icon {
  color: #54a547;
  border: 4px solid #54a547;
  border-radius: 50%;
  max-width: 150px;
  height: 150px;
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
}
.statusRedirect i {
  font-size: 5em;
  color: #54a547;
}
.statusRedirect {
  text-align: center;
}
.statusRedirect h2 {
  font-size: 2em;
  color: #54a547;
}

.statusRedirect.error h2 {
  font-size: 2em;
  color: #ff0000;
}

.integrationListWrapper {
  width: 100%;
  padding-top: 10px;
}
.integrationListWrapper .integrationCard .image {
  margin-bottom: 20px;
  text-align: center;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.integrationListWrapper .integrationCard {
  border-radius: 4px;
  margin-bottom: 30px;
  background: #fff;
  box-shadow: 0px 1px 2px #1b76af;
  border: 1px solid #ccc;
  padding: 25px;
  justify-content: space-between;
  cursor: pointer;
  transition: all ease 0.2s;
  height: 200px;
  display: flex;
  flex-direction: column;
}

button.cus-primary-bg-btn.ingration {
  width: 100%;
  font-size: 1em !important;
}

.redirectingOverly {
  position: fixed;
  z-index: 999999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  line-height: 100vh;
  text-align: center;
  color: #000;
  font-weight: 700;
  font-size: 2em;
}

.integrationDetails .heading {
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.integrationDetails .descriptionText {
  font-style: italic;
  position: relative;
}
.integrationDetails .image img {
  max-height: 70px;
}

.subFieldsWrapper {
  margin-bottom: 15px;
}

.alightVerticalTop {
  align-items: top;
  display: flex;
}
.alightVerticalCenter {
  align-items: center;
  display: flex;
}

.mb-15 {
  margin-bottom: 15px;
}

.linkIntegration:hover {
  color: #0056b3;
}
.linkIntegration {
  cursor: pointer;
  color: #007bff;
}

.marketPlaceInt.hide {
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.marketPlaceInt.show {
  height: auto;
}
.marketPlaceInt {
  display: flex;
  align-items: baseline;
  width: 100%;
}

.marketFilterWrapper {
  padding: 20px 0 0;
  transition: 0.3s ease;
}

.searchBtn {
  margin-left: 5px;
  line-height: 1.4 !important;
}

.modal_container_rate_card .modal_input {
  align-items: baseline;
}

.inlargeImageSlider {
  margin-bottom: 35px;
}

.sliderSmall:hover .maximize {
  display: block;
}
.sliderSmall img {
  max-height: 450px;
}
.sliderSmall {
  position: relative;
}
.sliderSmall .maximize {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  min-width: auto !important;
  display: none;
}

.top_modal_header_container.no_mrg {
  margin-bottom: 25px;
}

.main_content_table .fundMngTble.table tbody th,
.main_content_table .fundMngTble.table tbody tr td {
  padding: 0.6rem !important;
}

.payButton {
  background-color: #1b76af !important;
}

.summaryTable {
  max-width: 650px;
  margin: 0 auto;
  padding: 24px;
}

.rechargeAction {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 15px;
  border-top: 1px solid #ccc;
  margin-top: 15px;
}

.summaryTable .acceptTcBox {
  margin-top: 10px;
  /* padding-left: 20px; */
}
.summaryTable .col {
  line-height: 2.4em;
  text-overflow: unset;
  word-break: break-all;
}

.keyFieldWrapper.shipmentKeyFieldWrapper {
  margin-top: 10px;
  margin-bottom: 25px;
}
.optionsList {
  margin-top: 10px;
}
.keyFieldWrapper.shipmentKeyFieldWrapper button {
  opacity: 1;
}
.keyFieldWrapper.shipmentKeyFieldWrapper input {
  max-width: 100%;
}
.activeSellerListInput {
  font-size: small;
}

.mr-15 {
  margin-right: 15px;
}

.activeSellerListInput.list--is-disabled .list__control .list__single-value {
  color: #495057;
}

.activeSellerListInput.list--is-disabled .list__control {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

.main_content_table .carrierListingTable.table .cus-pencil-icon-sec {
  margin-left: 40px;
  cursor: pointer;
}

.PhoneInputCountry {
  display: none !important;
  background: transparent;
}
.PhoneInput--disabled {
  background-color: #e9ecef !important;
}
.PhoneInputInput:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.PhoneInputInput,
.PhoneInputInput:focus-visible {
  border: 0;
  outline: none;
}

.carrierListSelect {
  width: 100% !important;
}

.actions-margin-filter {
  padding-top: 30px;
}
.alignCenter {
  text-align: center;
}

.list-value--is-disabled .list-value__control .list-value__single-value {
  color: #333;
}
.list-value--is-disabled .list-value__control {
  background-color: #e9ecef;
  border-color: #ced4da;
}

.inTabElement {
  margin-top: 0;
  background: #fff;
}

.smallTxt {
  font-size: 11px;
}

.disableTble {
  opacity: 0.5;
  pointer-events: none;
}

.enableTble {
  opacity: 1;
  pointer-events: initial;
}

.dimensionCol {
  display: flex;
  align-items: baseline;
}
.dimensionColBg {
  background: #f1f1f1;
  padding: 10px 0;
}

.selectAllWrapper {
  padding: 0 6px;
}
.zoneCalculation {
  max-width: 200px;
}

.historyFilter .list__control {
  max-height: 65px;
}
.batchListing .dropdown-menu.show {
  width: auto !important;
}
.link {
  color: #4597ca;
  cursor: pointer;
}
.equalHeight {
  display: grid;
}

.inlineFlexPrintCost {
  display: flex;
  align-items: baseline;
}
.inlineFlex {
  display: inline-flex;
}

.imageBlock {
  background-color: #fff;
  width: 100%;
  padding: 15px;
}

.palleteWrapper {
  border-top: 10px solid #f3f6f8;
  margin-top: 10px;
}

.sectionHeading {
  font-weight: 700;
  font-size: 17px;
}

.d_flex {
  display: flex;
  align-items: center;
}
.detailBox {
  z-index: 1;
}

.fsIcon {
  font-size: 30px;
}

.iconBtns {
  display: flex;
  align-items: end;
}

.flexEnd {
  align-items: end;
  display: flex;
}
.flexOut {
  align-items: end;
  display: flex;
  margin-top: 40px;
}
.form-control.textArea {
  min-height: 70px;
}

.tooltipAddress p {
  margin-bottom: 4px;
}

.addressFormRow {
  border: 1px solid #333;
  padding: 10px;
}

.dimesntionsBlock h3 {
  margin: 0 0 15px;
  font-weight: 600;
  font-size: 15px;
}
.dimesntionsBlock {
  background: #f2f2f2;
  padding: 16px;
}
.dimesntionsBlock.dimesntionsBlockDark {
  background: #fff;
}
.palleteCardHead {
  border-bottom: 2px solid #d0d0d0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
}

.iconBtn {
  color: #fff !important;
  background: #337ab7 !important;
  padding: 2px 8px !important;
  margin-left: 8px;
}

.cardBody {
  min-height: 175px;
  max-height: 175px;
  overflow-y: auto;
  padding-right: 15px;
}
.cardBody.inventory {
  padding-right: 0;
}

.palleteItemRow {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.palleteItemRow .palleteName {
  margin-left: 8px;
}

.paletteActions {
  justify-content: end;
  display: flex;
}

.cursorPointer {
  cursor: pointer;
}

.inBoundDefaultImage {
  max-height: 450px;
  text-align: center;
}
.zIndex1 {
  position: relative;
  z-index: 1;
}

.zIndex2 {
  position: relative;
  z-index: 2;
}

.codeOptions input[type='radio']:first-child {
  margin-left: 0;
}
.codeOptions input[type='radio'] {
  margin-left: 15px;
  margin-right: 0;
}
.codeOptions {
  margin-top: 35px;
}
.custom-code input[type='radio']:first-child {
  margin-left: 0;
}
.custom-code input[type='radio'] {
  margin-left: 15px;
  margin-right: 0;
}
.custom-code {
  margin-top: 10px;
  display: flex;
}
.alignEnd {
  align-content: end;
}

.borderBox,
.selectInput {
  border-radius: 0;
  border: 1px solid #aaaaaa;
  padding: 3px 0;
  margin-bottom: 10px;
  width: 100%;
  min-height: 32px;
}

.borderBox {
  border: 1px solid rgb(229 229 229);
}

.selectInput {
  padding: 2px 3px;
}
.maxHeight40 {
  max-height: 35px;
}

.selectInput.barCode.form-control {
  padding: 2px 3px !important;
  height: 32px !important;
}

.filterMenu .date-picker {
  width: 100%;
}

.table.tbleSelectSku {
  border: 1px solid #ccc;
}

.horizontalAlignedTbl th {
  white-space: pre !important;
}

.tooltipTxt {
  max-width: 280px;
  word-break: normal;
}

.d-flex {
  display: flex;
}

@media all and (max-width: 640px) {
  .walletBalanceSection {
    margin-right: 5px;
    margin-left: 6px;
  }
}

.marketplace-table {
  background-color: #ddd;
  user-select: none;
  cursor: pointer;
}
.custom-scroll-remove {
  overflow: hidden;
}
.productskuListing .modal-content {
  width: 100% !important;
}
.top_modal_header_container.con_mrg {
  margin-bottom: 0px;
}
.link.v {
  color: #337ab7;
  cursor: pointer;
}
.sellerMenu .dropdown-item.active {
  background-color: #1b76af;
}
.sellerMenu .dropdown-item {
  padding: 5px 8px;
}
.sellerMenu * {
  box-sizing: border-box;
}
.sellerMenu.dropdown-menu.show {
  padding: 15px 15px 0;
  right: 0 !important;
  width: 300px;
}
.textSmall {
  font-size: 12px;
  line-height: 0.1;
  font-weight: 100;
}

.viewItem {
  pointer-events: none;
}

.fullPageLoader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderContainer {
  background-color: #000;
  border-radius: 16px;
  padding: 20px;
}

.dropdown-item {
  white-space: normal !important;
}

.filterActionReturn {
  text-align: right;
}

.fntError {
  font-size: 14px !important;
}

.disabledRow {
  background: #f0f0f0;
}

.editedRowBarCode {
  background: rgba(69, 151, 202, 0.3);
}

.ribbon-wrapper {
  position: relative;
}

.ribbon-1 {
  background: #08769b;
  display: inline-block;
  color: white;
  font-size: 14px;
  padding: 0 4px;
}

.capitalize::first-letter {
  text-transform: capitalize;
}

.refundWrapper .requestArea {
  min-height: 100px;
}
.refundWrapper .queryBox p {
  font-size: 14px;
  margin: 0 0 10px;
  font-weight: normal;
}
.refundWrapper .queryBox h2 {
  font-size: 22px;
}
.refundTable.main_content_table {
  border-top: 0;
}

.refundWrapper {
  padding: 20px 15px;
}

.table.tblOutbound {
  border: solid 1px #dedede;
}

.uploadContainer .inlineBlock {
  display: inline-block;
}

.items-wrapper {
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
}

.permissionTbl .expanded {
  background-color: #daf0fc;
}

.permissionTbl .heading {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
}
.permissionTbl .module,
.permissionTbl .ml-permission {
  font-weight: 600;
  font-size: 12px;
}
.permissionRow {
  transition: 1s ease;
  border-bottom: 1px solid #dfdfdf;
}
.permissionRow:hover {
  background-color: #e0ecf3;
}
.infoPermission {
  font-size: 14px;
  font-weight: 400;
}
.permissionTbl .form-check-input {
  margin-left: 0;
}

.permissionTbl * {
  transition: 1s ease all;
}

.hidLbl {
  visibility: hidden;
}

.ml-permission {
  margin-left: 25px;
}
.hamburgerMenu {
  padding: 15px;
  border: 0;
  position: fixed;
  z-index: 9;
  background-color: transparent;
  font-size: 17px;
  padding-top: 25px;
}
.bookingWrapper {
  display: flex;
}

.fileUpload.inventory.mobDevices {
  display: none;
}
.mobDevices {
  display: none;
}

.menuOuter {
  width: 100%;
  display: block;
}

.sidenav---expanded---1KdUL
  .sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  + .sidenav---navtext---1AE_f {
  width: auto;
}

.tbl-btch tr th {
  background-color: #fff;
}

.scrollableXAxis {
  overflow: auto;
}
.permissionTbl {
  min-width: 1000px;
  padding: 0 15px;
}
.tableMobile th {
  background-color: #fff;
}
.tableMobile th,
.tableMobile td {
  padding: 10px;
}

.table th {
  background: #fff;
}

.marginConfigWrapper {
  padding-top: 10px;
}

.cus-action-group-password {
  display: flex;
  justify-content: space-between;
}

.tableWide {
  width: max-content !important;
  min-width: 100%;
}

.barCodeAction input[type='radio'] {
  margin-left: 0;
}

.maxHeightSelect .list__control,
.maxHeightSelect .list-value__control {
  max-height: 100px;
  overflow: auto;
}

.fullDatePicker .date-picker {
  width: 100%;
}
.react-datepicker-wrapper .react-datepicker__view-calendar-icon input {
  padding: 8px 10px 8px 30px;
}
.itemView label,
.itemView input {
  font-size: 12px;
}
.itemView label {
  margin-bottom: 2px;
}
.itemView:empty {
  display: none;
}
.itemView {
  padding: 20px 10px;
  background: #ccdbed;
}

.addressForm .input-label-txt,
.addressForm label {
  font-size: 13px;
  margin-bottom: 4px;
}

.printLabelCost label {
  margin: 0;
}
.printLabelCost {
  display: flex;
}

.asyncListing .dropdown-menu {
  font-size: 12px;
}

.fontFix {
  font-size: 13px;
}

.fw-3 {
  font-size: 15px;
}

.noBrdr {
  border: 0;
  padding: 0;
}

.subHeadingInner2 {
  font-size: 16px;
  font-weight: 600;
}

.subHeadingInner {
  font-size: 13px;
  font-weight: 600;
}

.btn,
.btn-outline-primary {
  font-size: 13px !important;
}

.shipCols .col {
  padding-right: 0;
}

.srch-pt0 {
  padding-top: 0px !important;
}

.fw600 {
  font-weight: 600;
}

.table tbody tr:hover {
  background-color: #f3f6f8;
}

.btn.cus-seconday-bg-btn.fullIconBtn {
  padding: 7px;
  text-align: center;
  max-height: 34px;
  min-height: 34px;
}

.tootipText {
  font-size: 13px !important;
}

.incomingAccordion .consignmentDetails .accordion-header button {
  border-radius: 4px 4px 0 0;
  background-color: #e3e3e3;
}

.incomingAccordion .consignmentDetails .accordion-header {
  margin-bottom: 0;
}

.incomingAccordion .consignmentDetails .accordion-collapse {
  background: #f1f1f1;
  padding: 0 10px 15px;
}

.palletWrapper .palletSubHeader {
  padding: 8px 10px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  border: 1px solid #cdc6c6;
}

.palletOptions .option {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.palletWrapper .palletBody {
  padding: 0 15px;
}
.palletWrapper {
  background-color: #fff;
  padding-bottom: 10px;
}
.palletOptions {
  display: flex;
  align-items: center;
}

.row.rowWithScroll {
  flex-wrap: nowrap;
  overflow: auto;
  margin: 0;
}

.borderLessButton {
  border: 0;
  background: transparent;
  font-size: 20px;
  padding: 0;
  color: #1b76af;
  cursor: pointer;
  vertical-align: middle;
}

main.modalBarcode .main__tile_heading {
  display: none;
}
main.modalBarcode .addressDetailWrapper {
  padding: 0 !important;
}
main.modalBarcode,
main.modalBarcode .main_content,
main.modalBarcode .incoming_wrapper {
  margin-top: 0;
  padding-top: 0;
  box-shadow: none;
  border: 0;
}

.generateSkuInputWrapper {
  position: relative;
}
.generateSkuInputWrapper .generateBar {
  text-align: right;
  color: #1b76af;
  font-size: 18px;
  cursor: pointer;
}
.generateSkuInputWrapper .form-control {
  padding-right: 15px;
}

.listDesign .body .row:hover {
  background: #f1f1f1;
}
.listDesign .body .row {
  background: #fff;
}
.listDesign .heading {
  color: #fff;
  background: #1b76af;
  line-height: 2;
}

.innerAccourdion {
  padding-top: 0;
}
.incomingAccordion .consignmentDetails .innerAccordion .accordion-collapse {
  padding: 0;
}

.flexCenter {
  display: flex;
  align-items: center;
}

input.numBoxesInput::-webkit-inner-spin-button,
input.numBoxesInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.multiUploadDialog .select_react_Csv {
  width: 100%;
}
.multiUploadDialog {
  max-width: 750px;
  margin: 0 auto;
  width: 100%;
}

.infoItalicTxt {
  font-size: 12px;
  color: #212529;
  font-weight: 400;
}

.alignRightRes {
  text-align: right;
  padding-right: 10px;
}

.leftSideBar nav .mainMenu .fa {
  color: #fff !important;
}

.btn.qrBtn .fa {
  font-size: 20px !important;
}

.btn.qrBtn {
  padding: 5px;
}

.inlineMob {
  display: inline-block;
  vertical-align: top;
}

/* .barcodeScannerWrapper #html5-qrcode-button-camera-permission {
  border: 0;
  background: none;
  color: #1b76af;
}
.barcodeScannerWrapper #html5-qrcode-anchor-scan-type-change {
  text-decoration: none !important;
} */

.mandotoryCol {
  background: #f6a7a7;
}
.csvInfo {
  color: #ff0000;
  margin: 0;
  font-size: 12px;
}

@media all and (max-width: 880px) {
  .inlineMob {
    display: block;
    margin-top: 1rem;
  }
  .row .mb-w16 {
    width: 160px;
  }
  .flex-mob-res {
    display: flex;
  }
  .cus-login-pg .cus-login-pg-sec {
    display: block;
    padding: 20px 0 10px;
  }
  .cus-login-pg-sec .cus-login-pg-left-sec {
    width: 100%;
    max-height: 100px;
    background: transparent;
    margin-bottom: 20px;
  }
  .cus-login-pg-left-sec img.cus-main-logo-img {
    margin-top: 10px;
    height: 100px;
  }
  .cus-login-pg-sec .cus-login-pg-right-sec {
    width: 100%;
    padding: 10px;
  }
  .cus-welcome-txt {
    display: none;
  }
  .cus-login-form .cus-form-sec-heading {
    margin: 10px 0 10px;
    font-size: 18px;
  }
  .cus-action-btn-sec .loginBtn {
    width: 100%;
  }
  .loginFormSubmit .forgot-password {
    text-align: center;
    margin-top: 10px;
  }
  .cus-login-pg .cus-action-group {
    display: block;
  }
  .cus-action-btn-sec {
    width: 100%;
  }
  .cus-login-form-sec .form-group .cus-rel .form-controlzm {
    padding: 0 8px;
  }
  .cus-login-form-sec .form-group .cus-rel img.cus-sm-icon-cls {
    right: 5px;
  }
  .cus-login-form-sec .form-group .cus-rel {
    margin: 10px 0 15px;
  }
  .bookingSection.rightWidgetArea .create-batch.batchBooking {
    display: block;
  }
  .bookingSection.rightWidgetArea .batchBooking {
    text-align: left;
  }
  .bookingSection.rightWidgetArea .create-batch.batchBooking .batch-select {
    display: block;
  }
  .recent-bth-print {
    display: inline-block;
  }
  .bookingWrapper {
    margin-top: 10px;
  }
  .main__tile_heading.cus-btn-include-sec {
    align-items: flex-start;
    flex-direction: column;
  }
  .table thead th {
    vertical-align: middle;
  }
  .modal-content {
    width: 100%;
  }
  .rowBottomSpace .col-sm-12,
  .rowBottomSpace .col-12 {
    margin-bottom: 20px;
  }
  .show.filterContainer {
    max-height: unset;
  }
  .history-margin {
    margin-bottom: 0;
  }
  .desktopOnly {
    display: none;
  }
  .mobDevices {
    display: block;
  }
  .hidLbl {
    display: none;
  }
  .integrationListWrapper {
    width: auto;
  }
  .mobMb-10 {
    margin-bottom: 10px;
  }
  .mobMbTop-10 {
    margin-top: 10px;
  }
  .mobMbTop-30 {
    margin-top: 30px;
  }
  .main_content_table {
    padding: 0px 8px 16px;
  }
  .top_modal_header_container {
    margin-bottom: 10px;
  }
  .accordion-body {
    padding-left: 15px;
    padding-right: 15px;
  }
  .show.filterContainer.historyFilter {
    padding: 0 10px;
  }
  .packageCard {
    margin: 0 auto 30px;
  }
  .Back-btn-mobRes {
    top: 10px;
  }
  .mb-res {
    margin-bottom: 0px;
  }
  .flexOut-mob-res {
    margin-top: 0px;
  }
  .codeOptions-mob-res {
    margin-top: 0px;
  }
  .MobCentre {
    justify-content: center !important;
  }
}

@media all and (max-width: 680px) {
  .top_right_button_container .btn {
    margin-bottom: 8px;
  }
  .alignRightRes {
    text-align: left;
    padding-bottom: 5px;
  }
  .inbound-res-item-table {
    max-width: 700px;
    width: 580px;
    overflow-x: scroll;
    overflow: hidden;
  }
  .cus-loggedin-user-sec .mobileMarginRight-4 {
    margin-right: 0 !important;
  }
  .cssItem {
    max-width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
  .menuWrapper {
    display: none;
    transform: translateX(-80px);
    position: absolute;
    width: 0;
  }
  .main__container {
    padding: 24px 0px 24px 0px;
  }
  .containers {
    overflow-x: auto;
    grid-template-columns: 0.05fr 1fr 1fr 1fr;
  }
  .navbars {
    margin-left: 0;
    min-width: 100%;
    padding-left: 50px;
  }
  .leftSideBar,
  #sidebar {
    position: absolute;
    width: 0 !important;
    height: 0 !important;
  }

  .main__tile_heading.cus-btn-include-sec {
    display: block;
  }

  main {
    margin: 70px 10px 0 0;
  }

  .cus-content-only-sec .cus-mar-bottom20,
  .cus-content-only-sec .cus-mar-bottom {
    margin-bottom: 0;
  }
  .incoming_wrapper {
    overflow-x: unset;
  }
  .edit-company-detail {
    margin: 0px 0px 0px;
  }
  .Edit-Form-company {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .edit-company-address {
    margin: 0px 0px 0px;
  }
  .Edit-Form {
    margin-left: 0px !important;
  }
  .unit-margin-level {
    display: block;
  }
  .tableFixAcodianHead {
    overflow: auto;
  }
  .warehouseWrapper {
    display: block !important;
  }
}
@media all and (max-width: 1000px) {
  .show.filterContainer {
    max-height: unset;
  }
  .mb-2 {
    margin-bottom: 10px;
  }
}
.pl-pr {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
