.addressFrom .returnTo .addressBar {
  display: flex;
}
.custom-wei-onc {
  flex-direction: row;
}
.custom-wei-sub {
  display: flex;
}
.custom-wei-sub .col-4 {
  display: flex !important;
  flex-direction: column !important;
}
.custom-minus-btn {
  position: absolute;
  top: -55px !important;
  right: 10px !important;
}
.custom-1-btn {
  padding: 0;
  margin: 10px 15px;
  border: solid 1px #1b76af;
  width: 50% !important;
}
