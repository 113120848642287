.tbl-btch-hd button {
  border: 0 !important;
}
.numOfPage {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  color: black;
  width: 35%;
  align-items: center;
  font-size: 13px;
}
.totalPageSelect {
  width: 20%;
  padding: 6px;
  background: white;
  margin: 0px 10px;
  border-color: #ccc;
}
.pagination-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px 0 0;
}
.pagination {
  width: 40%;
  justify-content: flex-end;
}
.pagination .loaderWrapper_batch {
  display: flex;
  align-items: center;
}
.pagination select {
  padding: 0 13px;
  background: white;
  margin: 0 10px;
  border-color: #ccc;
}
.pagination button:active {
  background: #ccc;
}
.pagination button:disabled,
.pagination button:disabled:active {
  opacity: 0.5;
  background-color: transparent;
}
.pagination button {
  padding: 2px 14px;
  font-size: 13px;
  color: #000000;
  font-weight: 500;
  border: 1px solid #ccc;
}
.editOptions.row .col-12 .form-control {
  margin: 5px 0;
  padding: 0 3px !important;
}
.formDetail .row .col-4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.formDetail .row .col-4 aside {
  margin-left: 5px;
  font-weight: 600;
}
.custom-first-input span button {
  border: 0;
  background-color: transparent;
}
.createBatchForm .formActions {
  margin-top: 10px;
  text-align: right;
}

.full-screen-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
}

.batchListing .dropdown-menu {
  font-size: 13px;
}

@media all and (max-width: 880px) {
  /*Pagination*/
  .pagination-wrapper {
    display: block;
  }
  .numOfPage {
    justify-content: center;
    width: 100%;
  }

  .pagination {
    width: 100%;
  }
  .pagination select {
    padding: 6px 13px;
  }
}
