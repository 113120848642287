.modal_container_rate_card {
  width: 95%;
  margin-left: 2%;
}
.modal_header {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}
.modal_header_container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  padding: 15px 0 5px 0;
  margin-bottom: 10px;
}
.modal_input {
  margin-bottom: 20px;
}
.drag_drop_file > label {
  min-width: unset !important;
  width: 100% !important;
  height: 150px;
  background: #ccc;
  color: white;
  border: unset;
}
.drag_drop_file > label > div > span {
  color: white !important;
  font-size: 10px;
}
.drag_drop_file > label > div > span:nth-child(2) {
  display: none;
}
.status_bar {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}
.status_bar_container {
  margin-right: 0;
  margin-left: 0;
}
.download_template {
  color: rgba(34, 72, 170, 0.98);
  font-weight: 400;
  font-size: 12px;
  margin-top: 20px;
  cursor: pointer;
}
.xlsx_container {
  font-weight: 300;
  font-size: 10px;
}
.cancel_btn {
  background: rgba(0, 0, 0, 0.6);
  margin: 0 30px 0 0;
}
.cancel_btn:hover {
  background: rgba(0, 0, 0, 0.6);
}
.modal_btn {
  border-radius: 10px;
  margin-top: 15px;
}
.save_btn {
  background: #1d44cd;
  border-radius: 10px;
}
.save_btn:hover {
  background: #1d44cd;
  border-radius: 10px;
}
.modaL_footer {
  margin: 30px 0;
}
.file_name_container {
  background: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 6px;
}
